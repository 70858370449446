import React, { useState,  useEffect} from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {useNavigate} from 'react-router'

import BasicCRUD from './../core/basic-crud'
import PopUpDialog from './../core/popup-dialog'
import CustomersChats from './chat-requests'
import MessagesRepliesViews from './message-repliesView'
import ChatsViews from './chatsViews'

import {PUBLIC_URL} from './../../const'
import AppContext from "./../../app-context"

import { socket, requestData , socketUseEffect} from './../../socket';

import {DrawTime, DrawDetails, DrawCustomer, DrawPage} from './sessions/views-templates'

import PageViews from './page-views'


const Sessions = (props) => {
    const { tab } = useParams();
    let curTab = isNaN(tab) ? 0 : (tab - 1 < 0 ? 0 : (tab - 1))

    const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);

    const listeners = [
        {name: "sites", fn: "list", args: {}, saveDataFN: setSiteData, interval: 10000},
        {name: "history", fn: "list", args: {status: "0"}, saveDataFN: setData, responsePrefix: "sesions"},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const object = "history"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    //const [activeSite, setActiveSite] = useState(siteID)
    const [activeSite, setActiveSite] = useState(curTab)

    const [clientToken, setClientToken]= useState("")
    const [customerID, setCustomerID]= useState("")
    const [currentOnlineID, setCurrentOnlineID]= useState("")

    const [showPageViewDld, setShowPageViewDld] = useState(false);
    const [showInviteToChatDld, setShowInviteToChatDld] = useState(false);
    const [showCustomerMessagesDld, setShowCustomerMessagesDld] = useState(false);
    const [showRecentChatsDld, setShowRecentChatsDld] = useState(false);

    const  columns = [
        {label:"Customer", key: "name", format: DrawCustomer},
        {label:"Chats / Messages / View", key: "details", empty: ' - ', format: DrawDetails},
        {label:"Session time", key: "session_time", empty: ' - ', format: DrawTime},
        {label:"Page", key: "url", format: DrawPage},
        {label:"Country", key: "country"},
        {label:"IP", key: "ip"},
    ]

    const fields = [
        {
            type: "wizard",//tab
            name: "User Details",
            image: "ti ti-home-2 fs-4",
            view: "cycle",
            fields: [

            ]
        }
    ]

    const options = [
        {id: 1, name: "Hide Zero Time"}
    ]

    const optionsList = [
        {id: 1, name: "Option 1"},
        {id: 2, name: "Option 2"},
        {id: 3, name: "Option 3"},
    ]

    const searchFields = {
        simple: [
            {
                type: "tab",
                name: "",
                image: "",
                fields: [
                    [
                        {label: "Country", key: "country",
                            beforeField: <><i style={{top: "14px"}} className="ti ti-search position-absolute start-0 translate-middle-y fs-6 text-dark ms-3 relative"></i></>,
                            classField: "product-search ps-5",
                            styleField: {marginTop: "-20px"}},
                        {label: "Page", key: "page",
                            beforeField: <><i style={{top: "14px"}} className="ti ti-search position-absolute start-0 translate-middle-y fs-6 text-dark ms-3 relative"></i></>,
                            classField: "product-search ps-5",
                            styleField: {marginTop: "-20px"}},
                        {label: "Customer", key: "customer",
                            beforeField: <><i style={{top: "14px"}} className="ti ti-search position-absolute start-0 translate-middle-y fs-6 text-dark ms-3 relative"></i></>,
                            classField: "product-search ps-5",
                            styleField: {marginTop: "-20px"}},
                        {label: "", data: options, key: "zero", type: "checkbox", styleField: {marginTop: "11px"}},
                    ],
                    [
                        {label: "Search", key: "text",
                            beforeField: <><i style={{top: "14px"}} className="ti ti-search position-absolute start-0 translate-middle-y fs-6 text-dark ms-3 relative"></i></>,
                            classField: "product-search ps-5",
                            styleField: {marginTop: "-20px"}},
                    ],
                ]
            },
        ]
    }

    const simpleSearch = (props) => {
        return <>
            <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search ..." value={props.searchData} onChange={(event) => {
                if (!props.disableSaveFilters) {
                    localStorage.setItem(`${props.object}_simple_search_text`, event.target.value)
                }
                props.setSearchData(event.target.value)
            }} onKeyPress={props.search}/>
            <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
        </>
    }
    const isShowMenuItem = (action, data) => {
        if (action == "requests"){
            if(data['chat_requests'] < 1){
                return false
            }
        }

        if (action == "messages"){
            if(data['messages'] < 1){
                return false
            }
        }

        return true
    }

    //function fieldsChange(event, key){
    //    setFieldsData({...fieldsData, [key]: event.target.value})
    //}

    const tableAction = (action, data) => {

        setClientToken(data['token'])
        setCurrentOnlineID(data['id'])
        setCustomerID(data['customer_id'])

        if(action == "messages"){
            setShowCustomerMessagesDld(true)
        }

        if(action == "history"){
            setShowPageViewDld(true)
        }

        if(action == "requests") {
            setShowRecentChatsDld(true)
        }
    }

    const actions = [
        {name: "history", label:"Views History"},
        {name: "requests", label:"Chat History", fn: isShowMenuItem},
        {name: "messages", label:"Client Messages", fn: isShowMenuItem},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    const navigate = useNavigate();

    const activeSiteID =siteData.data && siteData.data[activeSite] && siteData.data[activeSite].id || 0

    let multiActionsData = data.users && data.users[activeSiteID] ? data.users[activeSiteID] : {data: [], rowsCount: 0}

    //let filters = {customer_token: clientToken, id: currentOnlineID}
    let filters = {customer_token: clientToken, online_id: currentOnlineID, customer_id: customerID}

    return (
        <>
            <PopUpDialog title={`Page View History`} show={showPageViewDld} hideDialog={setShowPageViewDld}>
               <PageViews filters={filters} data={props.pagehistoryData || {data: [], rowsCount: 0}} />
            </PopUpDialog>

            <PopUpDialog title={`Customer Messages`} show={showCustomerMessagesDld} hideDialog={setShowCustomerMessagesDld}>
                <MessagesRepliesViews  filters={filters}  />
            </PopUpDialog>

            <PopUpDialog title={`Recently Chats`} show={showRecentChatsDld} hideDialog={setShowRecentChatsDld}>
                <ChatsViews filters={filters} />
            </PopUpDialog>

            <BasicCRUD
                filters={{response_prefix: "sesions"}}
                title={`Old Sessions`}
                subTitle={`clients site research history`}
                homeURL={"/dashboard"}
                hideGroupActions={true}
                object={object} args={{status: "0"}} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                tableAction={tableAction}
                multiActions={multiActions} data={multiActionsData}
                simpleSearch1={simpleSearch}
                searchFields={searchFields}
            >

                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    {siteData.data && Object.keys(siteData.data).map((index) => {

                        var activeClass = ""
                        if (index == activeSite) activeClass = "active"

                        return (
                            <>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 ${activeClass}`}
                                            id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                            role="tab" aria-controls="pills-account" aria-selected="true"
                                            onClick={() => {
                                                const newIndex = parseInt(index) + 1;
                                                setActiveSite(index); navigate(`/sessions/${newIndex}`, { replace: true });

                                                socket.emit(`${object}:list`, {token: AppContext.user.token, page: 1, items_count: AppContext.rows_per_page, status: "0", simple_search_text: "", response_prefix: "sesions"});
                                            }}
                                    >
                                        <i className="ti ti-user-circle me-2 fs-6"></i>
                                        <span className="d-none d-md-block">{siteData.data[index].name}</span>
                                    </button>
                                </li>
                            </>
                        )

                    })}
                </ul>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Agent</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Agent</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Message</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  Sessions;
