import React, { useState, useEffect, forwardRef} from 'react';
import { socket } from './../../socket';

import AppContext from "./../../app-context"
import closeOnClickOutside from './../../components/core/close-on-click-outside'
import RenderFormFields from './forms'

//ActionsDropDown = (props) => {
const ActionsDropDownWrapped = forwardRef((props, ref) => {
    const {open, setOpen} = props

    var className = "btn btn-light dropdown-toggle"
    var actionClassName = "dropdown-menu"

    if(open){
        className = "btn btn-light dropdown-toggle show"
        actionClassName = "dropdown-menu show"
    }

    const handleOpenActions = () => {
        setOpen(open ? 0 : 1)
    }

    const actions = props.actions || []
    const tableAction = props.tableAction || null

    const title = props.title || ""


    return (
        <>
            {actions && actions.length >0 ? (
                <>
                    <button className={className} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleOpenActions}>
                        {title} <i className="ti ti-dots-vertical"></i>
                    </button>
                    <ul className={actionClassName} aria-labelledby="dropdownMenuButton" ref={ref}>
                        {actions.map((item) => {
                            var isShow = true
                            if (item.fn){
                                isShow = item.fn(item.name, props.data)
                            }

                            if(!isShow){
                                return null
                            }

                            return (
                                <li><a className="dropdown-item" href="javascript:void(0)" onClick={() => {
                                    setOpen(0)
                                    if (tableAction !== null){
                                        tableAction(item.name, props.data || {})
                                    }
                                }}>{item.label}</a></li>
                            )
                        })}
                    </ul>
                </>
            ) : null}

        </>
    )
});

const ActionsDropDown =  closeOnClickOutside(ActionsDropDownWrapped)

const DrowRow = (props) => {
    //const itemsCount = props.itemsCount

    const actions = props.actions || []
    const tableAction = props.tableAction || null

    const handleChange = (itemID) => {
        //selectedItems={selectedItems}, setSelectedItems={setSelectedItems}
        //props.selectedItems["item_0"]
        console.log("checkbox - ", !props.selectedItems[`item_${itemID}`])

        let choosedItems = {...props.selectedItems}
        choosedItems[`item_${itemID}`] = !props.selectedItems[`item_${itemID}`]

        console.log("checkboxes : ", choosedItems)
        props.setSelectedItems(choosedItems)
    }

    return (
        <tr>
            {!props.hideGroupActions && props.data && props.data['id'] ? (
                <td>
                    <div className="form-check mb-0">
                        <input className="form-check-input"
                               type="checkbox"
                               checked={props.selectedItems[`item_${props.data['id']}`] || 0}
                               onChange={() => handleChange(props.data['id'])} />
                    </div>
                </td>
            ): null}

            {props.data ?
                props.columns.map((item, index) => {
                    return props.data[item.key] || item.format ? <td>{item.format ? item.format(props.data) : props.data[item.key]}</td> : <td> - </td>
                }) : <td colspan={props.columns.length + 2}>{props.text ? props.text : " - "}</td>}

            {!props.hideActions && props.data && props.data['id'] ? (
                <td>
                    <ActionsDropDown data={props.data} actions={actions} tableAction={tableAction} />
                </td>
            ): null}
        </tr>
    )
}

const DrowColumns = (props) => {
    const handleChange = () => {
       //selectedItems={selectedItems}, setSelectedItems={setSelectedItems}
       //props.selectedItems["item_0"]
        //console.log("checkbox - ", !props.selectedItems["item_0"])

       /*
       props.data.data && props.data.data.length > 0 ? props.data.data.map((item, index) => {
                                    if(index >= itemsCount) return null;
       * */
       let choosedItems = null
       if(props.selectedItems){
           choosedItems =  {item_0: !props.selectedItems["item_0"]}
           if (!props.selectedItems["item_0"] && props.data.data && props.data.data.length > 0 ){
               props.data.data.forEach((item, index) => {
                   if(index >= props.itemsCount) return null;
                   choosedItems[`item_${item.id}`] = true
               })
           }
           console.log("checkboxes : ", choosedItems)
           props.setSelectedItems(choosedItems)
       }
    }
    return (
        <tr>
            {props.selectedItems && !props.hideGroupActions && <th scope="col" style={{width: "80px"}}>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox"
                           checked={props.selectedItems["item_0"] || 0}
                           onChange={handleChange} />
                </div>
            </th>}
            {props.columns.map(item => { return <th scope="col" style={{width: item.width}}>{item.label}</th>})}
            {!props.hideActions && <th scope="col" style={{width: "100px"}}>Actions</th>}
        </tr>
    )
}

const Table = (props) => {
    //const [pageNum, setPageNum] = useState(AppContext.pages && AppContext.pages[props.object] && AppContext.pages[props.object].pageNum || 1)
    const [itemsCount, setItemsCount] = useState(AppContext.rows_per_page)

    const [fieldsData, setFieldsData] = useState(JSON.parse(localStorage.getItem(`${props.object}_search`)) || {})
    const [response, setResponse] = useState({});

    async function fieldsChange(event, key, item_id=null){
        var data = {...fieldsData}
        if(item_id == null) {
            data = {...data, [key]: event.target.value}
        }else{
            //console.log("push ::: ", data, key)
            if (!data[key]) data[key] = []
            if(data[key].includes(item_id)){
                data[key] = data[key].filter(e => e !== item_id)
            }else{
                data[key].push(item_id)
            }

        }

        setFieldsData(data)

        return data
        //console.log("fields data::::", fieldsData)
    }

    //function fieldsChange(event, key){
    //    setFieldsData({...fieldsData, [key]: event.target.value})
    //}

    const itemsCountPerTable = [5,10,25]

    var fromRowsCount = (props.pageNum-1) * itemsCount + 1
    var toRowsCount = props.pageNum * itemsCount
    if(toRowsCount > props.data.rowsCount) toRowsCount = props.data.rowsCount

    const actions = props.actions || []
    const tableAction = props.tableAction || null

    const multiActions = props.multiActions || []

    const search = (event) => {
        if(event.key === "Enter"){
            let data = event.data || fieldsData
            const page = event.page || 1
            //if (!props.disableSaveFilters) {
            AppContext.pages[props.object] = {...AppContext.pages[props.object], pageNum: page}
            //}

            props.setResponse({})
            props.setSelectedItems({})
            props.setPageNum(page)

            const args = props.args || {}/*simple_search_text: props.searchData*/

            let searchData = {}
            let saveSearchData = {...data}

            Object.keys(data).forEach((field) => {
                let propName = `simple_search_${field}`
                searchData = {...searchData, [propName]: data[field]}
            })

            const resData = {token: AppContext.user.token, page: page, items_count: itemsCount, ...searchData, ...props.filters, ...args}

            socket.emit(`${props.object}:list`, resData);

            if (!props.disableSaveFilters) {
                localStorage.setItem(`${props.object}_search`, JSON.stringify(saveSearchData))
            }

            localStorage.setItem(`${props.object}_settings`, JSON.stringify(saveSearchData))
        }
    }


    let width = 12
    if (props.addTitle) {
        width = 9
    }

    const defaultSearchFields = [
        {
            type: "tab",
            name: "",
            image: "",
            fields: [
                [
                    {
                        label: "Search", key: "text",
                        beforeField: <><i style={{top: "14px"}} className="ti ti-search position-absolute start-0 translate-middle-y fs-6 text-dark ms-3 relative"></i></>,
                        classField: "product-search ps-5",
                        styleField: {marginTop: "-20px"}
                    },
                ]
            ]
        },
    ]

    let searchFields = props.searchFields && props.searchFields.simple || defaultSearchFields

    /*
    {props.simpleSearch ? <props.simpleSearch searchData={props.searchData} disableSaveFilters={props.disableSaveFilters} object={props.object} setSearchData={props.setSearchData} search={search} /> :
        <>
            <input type="text" className="form-control product-search ps-5" id="input-search"
                   placeholder="Search ..."
                   value={props.searchData} onChange={(event) => {
                if (!props.disableSaveFilters) {
                    localStorage.setItem(`${props.object}_simple_search_text`, event.target.value)
                }
                props.setSearchData(event.target.value)
            }} onKeyPress={search} />
            <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
        </>
    }
    fieldsData1={fieldsData} fieldsChange1={fieldsChange} response1={response}
    */
    return (
        <>
            <div className="card card-body" style={{paddingBottom: "0px", paddingTop: "5px"}}>
                <div className="row">
                    <div className={`col-md-4 col-xl-${width}`}>
                        <div className="position-relative">
                            <RenderFormFields fields={searchFields} fieldsData={fieldsData} fieldsChange={fieldsChange} response={response} enterApply={search} hideBorders={true}/>
                        </div>
                    </div>
                    <div className="col-md-8 col-xl-3 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
                        {props.addTitle && <a href="javascript:void(0)" id="btn-add-contact"
                           className="btn btn-primary d-flex align-items-center" onClick={() => {props.addFunction()}}>
                            <i className="ti ti-users text-white me-1 fs-5"></i> {props.addTitle}
                        </a>}
                    </div>
                </div>
            </div>

            <div className="product-list">
                <div className="card">
                    <div className="card-body p-3">
                        <div className="table-responsive border rounded">
                            <table className="table align-middle text-nowrap mb-0">
                                <thead>
                                <DrowColumns hideActions={props.hideActions} hideGroupActions={props.hideGroupActions || false} columns={props.columns} selectedItems={props.selectedItems} setSelectedItems={props.setSelectedItems} itemsCount={itemsCount} data={props.data} />
                                </thead>
                                <tbody>
                                {props.data.data && props.data.data.length > 0 ? props.data.data.map((item, index) => {
                                    if(index >= itemsCount) return null;
                                    return <DrowRow hideActions={props.hideActions} hideGroupActions={props.hideGroupActions || false} columns={props.columns} data={item} actions={actions} tableAction={tableAction} selectedItems={props.selectedItems} setSelectedItems={props.setSelectedItems} />
                                }) : <DrowRow itemsCount={itemsCount} columns={props.columns} text="Empty items" actions={actions} tableAction={tableAction} />}
                                </tbody>
                            </table>
                            <ul className="nav nav-pills p-3 mb-3 rounded align-items-center card flex-row">
                                <li className="nav-item">
                                    {!props.hideGroupActions && <ActionsDropDown title={"Actions"} data={props.data} actions={multiActions} tableAction={tableAction} />}
                                </li>
                                <li className="nav-item ms-auto">
                                    <div className="d-flex align-items-center justify-content-end py-1">
                                        <p className="mb-0 fs-2">Rows per page:</p>

                                        <select className="form-select w-auto ms-0 ms-sm-2 me-8 me-sm-4 py-1 pe-7 ps-2 border-0" aria-label="Default select example" onChange={(event) => {
                                            const page = 1
                                            const itemsCount = event.target.value

                                            setItemsCount(itemsCount);
                                            AppContext.rows_per_page=itemsCount;
                                            AppContext.pages[props.object] = {...AppContext.pages[props.object], pageNum: page}

                                            //socket.emit(`${props.object}:list`, {token: AppContext.user.token, page: page, items_count: itemsCount, ...props.searchData, ...props.filters});

                                            search({key: "Enter", page: page})
                                            
                                            //props.setPageNum(page)
                                        }}>

                                            {itemsCountPerTable.map(item => {
                                                const selected = (item == itemsCount ? "selected" : "")
                                                return (
                                                    <option value={item} selected={selected}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <p className="mb-0 fs-2">{props.data.rowsCount < 1 ? 0 : fromRowsCount}–{toRowsCount} of {props.data.rowsCount}</p>
                                        <nav aria-label="...">
                                            <ul className="pagination justify-content-center mb-0 ms-8 ms-sm-9">
                                                <li className="page-item p-1">
                                                    <a className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                                       href="javascript:void(0)" onClick={() => {
                                                        var page = props.pageNum - 1
                                                        if (page < 1) return;
                                                        AppContext.pages[props.object] = {...AppContext.pages[props.object], pageNum: page}
                                                        
                                                        search({key: "Enter", page: page})
                                                        
                                                        //socket.emit(`${props.object}:list`, {token: AppContext.user.token, page: page, items_count: itemsCount, ...props.searchData, ...props.filters});
                                                        //props.setSelectedItems({})
                                                        //props.setResponse({})
                                                        //props.setPageNum(page)
                                                    }}><i className="ti ti-chevron-left"></i></a>
                                                </li>
                                                <li className="page-item p-1">
                                                    <a className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                                       href="javascript:void(0)" onClick={() => {

                                                        if (props.pageNum * itemsCount + 1  > props.data.rowsCount) return ;

                                                        const page = props.pageNum + 1

                                                        AppContext.pages[props.object] = {...AppContext.pages[props.object], pageNum: page}
                                                        search({key: "Enter", page: page})
                                                        
                                                        //socket.emit(`${props.object}:list`, {token: AppContext.user.token, page: page, items_count: itemsCount, ...props.searchData, ...props.filters});
                                                        //props.setSelectedItems({})
                                                        //props.setResponse({})
                                                        //props.setPageNum(page)
                                                    }}><i className="ti ti-chevron-right"></i></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export  default  Table;
