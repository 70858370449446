import React, { Component } from "react";
import {socket, requestData} from "./socket"

import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";

import MainFrame from "./components/page-elements/main-frame"

import Page404 from "./components/page-elements/page-404"
import LogIN from "./components/page-elements/page-login"

import Sessions from "./components/tables/sessions"
import CustomersOnline from "./components/tables/customers-online"
import ChatRequests from "./components/tables/chat-requests"
import CustomersMessages from "./components/tables/customers-messages"
import Departments from "./components/tables/departments"
import Agents from "./components/tables/agents"
import Sites from "./components/tables/sites"
import Abbreviations from "./components/tables/abbreviations"
import History from "./components/tables/history"
import Profile from "./components/tables/profile"
import Dashboard from "./components/tables/dashboard"


import AppContext from "./app-context"

const AppRoutes = (props) => {
    let routes = useRoutes([
        { path: "/", element: <MainFrame><Dashboard /></MainFrame> },
        { path: "/dashboard", element: <MainFrame><Dashboard /></MainFrame> },
        { path: "/sign-in", element: <LogIN />},
        { path: "/my-profile/:tab", element: <MainFrame><Profile /></MainFrame> },
        { path: "/online", element: <MainFrame><CustomersOnline getMessagesByCustomer={props.state.getMessagesByCustomer || []} /></MainFrame> },
        { path: "/online/:tab", element: <MainFrame><CustomersOnline getMessagesByCustomer={props.state.getMessagesByCustomer || []} /></MainFrame> },
        { path: "/sessions", element: <MainFrame><Sessions getMessagesByCustomer={props.state.getMessagesByCustomer || []} /></MainFrame> },
        { path: "/sessions/:tab", element: <MainFrame><Sessions getMessagesByCustomer={props.state.getMessagesByCustomer || []} /></MainFrame> },
        { path: "/chat-requests", element: <MainFrame><ChatRequests /></MainFrame> },
        { path: "/history", element: <MainFrame><History /></MainFrame> },
        { path: "/messages", element: <MainFrame><CustomersMessages /></MainFrame> },
        { path: "/messages/:tab", element: <MainFrame><CustomersMessages /></MainFrame> },
        { path: "/departments", element: <MainFrame><Departments /></MainFrame> },
        { path: "/abbreviations", element: <MainFrame><Abbreviations /></MainFrame> },
        { path: "/agents", element: <MainFrame><Agents /></MainFrame> },
        { path: "/sites", element: <MainFrame><Sites /></MainFrame> },
        { path: "*", element: <Page404 /> },
    ]);
    return routes;
};

            /*
             counters={props.state.generalCounters || {}}
            * */

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 0,
            isActive: false,
        };

        AppContext.user.id = "1"
        AppContext.user.name = "Artem Z"
        AppContext.user.role = "SEO"
        AppContext.user.email = "artem.z@avega-group.com"
    }


    updateState(key, data) {
        var info = {}
        info[key] = data

        this.setState((prvState) => ({
            ...prvState,
            ...info,
        }));

        console.log(" ::: update state ::: ", info)
    }

    startListerners(listObject = []){
        listObject.forEach((object) => {

            const action = object.fn || 'List'
            this.updateState(`${object.name}${action}`, [])

            let fn = "list"
            if(object.fn && object.fn != "") fn = object.fn

            const noListener = object.noListener || false

            if(noListener == false) {
                socket.on(`${object.name}:${fn}-data`, (data) => {
                    this.updateState(`${object.name}${action}`, data)
                });
            }

            requestData(object.name, object.args || {}, fn);

            if( object.interval &&  object.interval > 999 ){
                setInterval(() => {
                    requestData(object.name, object.args || {}, fn);
                }, object.interval);
            }
        })
    }

    componentDidMount() {
        //console.log("Ddd");
        if (!this.state.isActive) {

            const listObject = [
                //{name:"customersMessages", fn: "MessagesByCustomerList"},
                //{name:"customersRequests",},
            ]

            this.startListerners(listObject)
        }
    }

    getSeconds = (s) => {
        return `0${this.state.seconds % 60}`.slice(-2);
    };

    getMinutes = (s) => {
        return Math.floor(this.state.seconds / 60);
    };

    render() {
        return (
            <Router>
                <AppRoutes state={this.state} />
            </Router>
        );
    }
}

export default App;
