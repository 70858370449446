import {convertSecondToTime} from './../../core/lib/dates'

const DrawDetails = (data) => {
    return (
        <>
            <b>{data['visits']}</b>&nbsp;&nbsp;{data['chat_requests']} / {data['messages']} / {data['view']}
        </>
    )
}

const DrawTime = (data) => {
    const time = convertSecondToTime(data['session_time'])
    return (
        <>
            {time}
        </>
    )
}

const DrawCustomer = (data) => {
    return (
        <>
            {data['name']}<br />
            {data['updated_at']}
        </>
    )
}

/*
text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
} 
*/
const DrawPage = (data) => {
    return (
        <>
            <div style={{width: "250px", overflow: "hidden"}}>
                <div style={{textOverflow: "ellipsis", overflow: "hidden"}}>
                    {data['url']}
                </div>
            </div>
        </>   
    )    
}

export {DrawTime, DrawDetails, DrawCustomer, DrawPage}
