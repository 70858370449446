import React, { useState, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {useNavigate} from 'react-router'

import BasicCRUD from './../core/basic-crud'
import {PUBLIC_URL} from './../../const'
import AppContext from "./../../app-context"

import PopUpDialog from './../core/popup-dialog'
import CustomersChats from './chat-requests'

import MessagesRepliesViews from './message-repliesView'

import { socket, requestData, socketUseEffect } from './../../socket';
//class CustomersMessages extends Component{
const CustomersMessages = (props) => {
    const { tab } = useParams();
    let curTab = isNaN(tab) ? 0 : (tab - 1 < 0 ? 0 : (tab - 1))

    const object = "customersMessages"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})
    const [activeSite, setActiveSite] = useState(curTab)


    const [messageID, setMessageID] = useState(0);
    const [customerID, setCustomerID] = useState(0);

    const [showCustomerMessagesDld, setShowCustomerMessagesDld] = useState(false);

    const [data, setData] = useState([])
    const [siteData, setSiteData] = useState([]);

    const listeners = [
        {name: "customersMessages", fn: "list", args: {}, saveDataFN: setData, interval: 10000},
        {name: "sites", fn: "list", args: {}, saveDataFN: setSiteData, interval: 10000}
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const DrawSubject = (data) => {
        return (
            <>
                <h6 className="mb-1 fw-semibold chat-title">{data['name']}</h6>
                <span className="fs-3 text-truncate text-body-color d-block">{data['subject']}</span>
            </>
        )
    }

    const DrawAgent = (data) => {
        let status = <span className="badge text-bg-primary fs-2 rounded-4 py-1 px-2">open</span>
        if (data['status'] == 0){
            status = "closed"
        }
        return (
            <>
                <div className="d-flex align-items-center">
                    <span className="position-relative">
                        <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`} alt="user1" width="48" height="48" className="rounded-circle" />
                        <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </span>
                    <div className="ms-3 d-inline-block w-75">
                        <h6 className="mb-1 fw-semibold chat-title">{data['agent_name']}</h6>
                        <span className="fs-3 text-truncate text-body-color d-block">{status}</span>
                    </div>
                </div>

            </>
        )
    }

    const  columns = [
        {label:"Agent", key: "agent_name", format: DrawAgent},
        {label:"Subject", key: "subject", format: DrawSubject},
        {label:"Last Update", key: "updated_at"},
    ]

    const fields = [
        {
            type: "wizard",//tab
            name: "User Details",
            image: "ti ti-home-2 fs-4",
            view: "cycle",
            fields: [

            ]
        }
    ]

    const actions = [
        {name: "messages", label:"View"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    const navigate = useNavigate();

    const activeSiteID = siteData.data && siteData.data[activeSite] && siteData.data[activeSite].id || 0
    const multiActionsData = data.messages && data.messages[activeSiteID] ? data.messages[activeSiteID] : {data: [], rowsCount: 0}

    const tableAction = (action, data) => {
        if(action == "messages"){
            //socket.emit(`customersMessages:getMessagesByCustomer`, {...data, customer_token: data['token']})
            setCustomerID(data['customer_id'])
            setMessageID(data['id'])
            setShowCustomerMessagesDld(true)
        }
    }

    const filters = {customer_id: customerID, message_id: messageID}
    return (
        <>
            <PopUpDialog title={`Customer Messages`} show={showCustomerMessagesDld} hideDialog={setShowCustomerMessagesDld}>
                <MessagesRepliesViews  filters={filters}  />
            </PopUpDialog>

            <BasicCRUD
                title={`Messages (${data.messagesCount || 0})`}
                subTitle={`Messages`}
                homeURL={"/dashboard"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                tableAction={tableAction}
                multiActions={multiActions} data={multiActionsData} >

                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    {siteData.data && Object.keys(siteData.data).map((index) => {

                        var activeClass = ""
                        if (index == activeSite) activeClass = "active"


                        //if (data.messages && data.messages[siteData.data[index].id]){
                            return (
                                <>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 ${activeClass}`}
                                                id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                                role="tab" aria-controls="pills-account" aria-selected="true"
                                                onClick={() => {
                                                    const newIndex = parseInt(index) + 1; 
                                                    setActiveSite(index); 
                                                    navigate(`/messages/${newIndex}`, { replace: true });
                                                    socket.emit(`${object}:list`, {token: AppContext.user.token, page: 1, items_count: AppContext.rows_per_page, simple_search_text: ""});
                                                }}>
                                            <i className="ti ti-user-circle me-2 fs-6"></i>
                                            <span className="d-none d-md-block">{siteData.data[index].name}  {siteData.data[index].messages_count > 0 && <>({siteData.data[index].messages_count})</>}</span>
                                        </button>
                                    </li>
                                </>
                            )
                        //}

                        return null
                    })}
                </ul>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Agent</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Agent</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Message</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  CustomersMessages;
