import React, { useState } from 'react';

import BasicCRUD from './../core/basic-crud'
import {Link} from "react-router-dom";

const Departments = (props) => {
    const object = "departments"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const columns = [
        {label:"Name", key: "name"},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const fields = [
        {
            type: "tab",
            name: "",
            image: "",
            fields: [
                [{label: "Name", key: "name", default_value: ""}]
            ]
        },

    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    return (
        <>
            <BasicCRUD
                title={"Settings (`Departments`)"}
                subTitle={"Departments"}
                homeURL={"/dashboard"}
                addTitle="Add Departments" editTitle={"Edit Departments"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                multiActions={multiActions}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Departments</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Departments</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Departments</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to="/sites" className="sidebar-link" data-text="Sites" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Sites</span>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to="/departments" className="sidebar-link" data-text="Dashboard" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 active`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Departments</span>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to="/abbreviations" className="sidebar-link" data-text="Dashboard" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Abbreviations</span>
                            </button>
                        </Link>
                    </li>
                </ul>

            </BasicCRUD>
        </>
    )
}
export  default  Departments;
