const convertSecondToTime = (time) => {
    var textTime = ""

    let hours = 0
    let minute = Math.floor(time / 60)
    let second = time % 60

    hours =  Math.floor(minute / 60)
    minute = minute % 60

    if (hours < 10) {
        hours = `0${hours}`
    }
    if (minute < 10) {
        minute = `0${minute}`
    }
    if (second < 10) {
        second = `0${second}`
    }
    textTime = `${hours}:${minute}:${second}`

    return textTime
}

const convertSecondToTimeLeft = (time) => {
    var textTime = ""
    if (time < 60){
        time = Math.floor(time)
        textTime = ` ${time} minutes ago`

        if (time <= 1){
            textTime = ` 1 minute ago`
        }
    }

    time = Math.floor(time / 60)

    if (time > 0 && time < 24){
        textTime = ` ${time} hours ago`
    }

    time = Math.floor(time / 24)
    if (time >0 && time < 30){
        textTime = ` ${time} days ago`
    }

    return textTime
}

export {convertSecondToTime, convertSecondToTimeLeft}
