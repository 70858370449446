import React, { useState, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {useNavigate} from 'react-router'

import BasicCRUD from './../core/basic-crud'
import {PUBLIC_URL} from './../../const'
import AppContext from "./../../app-context"

import PopUpDialog from './../core/popup-dialog'
import ChatsViews from './chatsViews'
//import CustomersChats from './chat-requests'

import { socket, requestData, socketUseEffect } from './../../socket';

const History = (props) => {
    const { tab } = useParams();
    let curTab = isNaN(tab) ? 0 : (tab - 1 < 0 ? 0 : (tab - 1))

    const [activeSite, setActiveSite] = useState(curTab)

    const navigate = useNavigate();
    
    //const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);

    const listeners = [
        {name: "sites", fn: "list", args: {}, saveDataFN: setSiteData, interval: 10000},
        //{name: "history", fn: "list", args: {status: "0"}, saveDataFN: setData, responsePrefix: "sesions"},
    ]

    useEffect(() => socketUseEffect(listeners), []);
    
    const object = "closedChats"
    const args = {user_id: AppContext.user.id, status: "0"}

    const [showChatMessagesDld, setShowChatMessagesDld] = useState(false);

    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const [currentOnlineID, setCurrentOnlineID] = useState(0)
    const [currentChatID, setCurrentChatID] = useState(0)

    const DrawAgent = (data) => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <span className="position-relative">
                        <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`} alt="user1" width="48" height="48" className="rounded-circle" />
                        <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </span>
                    <div className="ms-3 d-inline-block w-75">
                        <h6 className="mb-1 fw-semibold chat-title">{data['agent_name']}</h6>
                        <span className="fs-3 text-truncate text-body-color d-block">{data['status'] == 0? "closed" : "open"}</span>
                    </div>
                </div>

            </>
        )
    }

    const DrawCustomer = (data) => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <span className="position-relative">
                        <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`} alt="user1" width="48" height="48" className="rounded-circle" />
                        <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </span>
                    <div className="ms-3 d-inline-block w-75">
                        <h6 className="mb-1 fw-semibold chat-title">{data['name']}</h6>
                        <span className="fs-3 text-truncate text-body-color d-block">{data['lastMessage']|| ""}</span>
                    </div>
                </div>

            </>
        )
    }

    const  columns = [
        {label:"Agent", key: "agent_name", format: DrawAgent},
        {label:"Customer/Question", key: "name", format: DrawCustomer},
        {label:"Last Update", key: "updated_at"},
    ]

    const fields = [
        {
            type: "wizard",//tab
            name: "User Details",
            image: "ti ti-home-2 fs-4",
            view: "cycle",
            fields: [

            ]
        },
    ]

    const actions = [
        {name: "chats", label:"View"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    const tableAction = (action, data) => {
        if(action == "chats"){

            setCurrentOnlineID(data['online_id'])
            setCurrentChatID(data['id'])

            setShowChatMessagesDld(true)
        }
    }

    let filters = {online_id: currentOnlineID, chat_id: currentChatID}

    return (
        <>
            <PopUpDialog title={`Chat Detatails`} show={showChatMessagesDld} hideDialog={setShowChatMessagesDld}>
                <ChatsViews  filters={filters} />
            </PopUpDialog>

            <BasicCRUD
                title={"History"}
                homeURL={"/dashboard"}
                object={object} args={args} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                tableAction={tableAction}
                multiActions={multiActions} >
                
                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    {siteData.data && Object.keys(siteData.data).map((index) => {

                        var activeClass = ""
                        if (index == activeSite) activeClass = "active"

                        return (
                            <>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 ${activeClass}`}
                                            id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                            role="tab" aria-controls="pills-account" aria-selected="true"
                                            onClick={() => {
                                                const newIndex = parseInt(index) + 1;
                                                setActiveSite(index); navigate(`/sessions/${newIndex}`, { replace: true });

                                                socket.emit(`${object}:list`, {token: AppContext.user.token, page: 1, items_count: AppContext.rows_per_page, status: "0", simple_search_text: "", response_prefix: "sesions"});
                                            }}
                                    >
                                        <i className="ti ti-user-circle me-2 fs-6"></i>
                                        <span className="d-none d-md-block">{siteData.data[index].name}</span>
                                    </button>
                                </li>
                            </>
                        )

                    })}
                </ul>
                
                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Chat History</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  History;


