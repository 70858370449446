import React, { useState,  useEffect} from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {useNavigate} from 'react-router'

import BasicCRUD from './../core/basic-crud'
import PopUpDialog from './../core/popup-dialog'
import CustomersChats from './chat-requests'
import MessagesRepliesViews from './message-repliesView'
import ChatsViews from './chatsViews'

import {PUBLIC_URL} from './../../const'
import AppContext from "./../../app-context"

import { socket, requestData , socketUseEffect} from './../../socket';
import {DrawTime, DrawDetails, DrawCustomer, DrawPage} from './sessions/views-templates'

import PageViews from './page-views'

const CustomersOnline = (props) => {
    const { tab } = useParams();
    let curTab = isNaN(tab) ? 0 : (tab - 1 < 0 ? 0 : (tab - 1))

    const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);

    const listeners = [
        {name: "sites", fn: "list", args: {}, saveDataFN: setSiteData, interval: 10000},
        {name: "history", fn: "list", args: {status: "1"}, saveDataFN: setData, responsePrefix: "online", interval: 10000},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const object = "history"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    //const [activeSite, setActiveSite] = useState(siteID)
    const [activeSite, setActiveSite] = useState(curTab)

    const [clientToken, setClientToken]= useState("")
    const [currentOnlineID, setCurrentOnlineID]= useState("")
    const [customerID, setCustomerID]= useState("")

    const [showPageViewDld, setShowPageViewDld] = useState(false);
    const [showInviteToChatDld, setShowInviteToChatDld] = useState(false);
    const [showCustomerMessagesDld, setShowCustomerMessagesDld] = useState(false);
    const [showRecentChatsDld, setShowRecentChatsDld] = useState(false);

    const  columns = [
        {label:"Customer", key: "name", format: DrawCustomer},
        {label:"Chats / Messages / View", key: "details", empty: ' - ', format: DrawDetails},
        {label:"Session time", key: "session_time", empty: ' - ', format: DrawTime},
        {label:"Page", key: "url", format: DrawPage},
        {label:"Country", key: "country"},
        {label:"IP", key: "ip"},
    ]

    const fields = [
        {
            type: "wizard",//tab
            name: "User Details",
            image: "ti ti-home-2 fs-4",
            view: "cycle",
            fields: [

            ]
        }
    ]

    const isShowMenuItem = (action, data) => {
        if (action == "requests"){
            if(data['chat_requests'] < 1){
                return false
            }
        }

        if (action == "messages"){
            if(data['messages'] < 1){
                return false
            }
        }

        return true
    }

    function fieldsChange(event, key){
        setFieldsData({...fieldsData, [key]: event.target.value})
    }

    const inviteToChat = () => {
        console.log("::::::::::::: invite to chat :::: ", fieldsData)

        alert(`invite ${fieldsData["invite_message"]} - ${fieldsData.clientToken}`)
        socket.emit(`user:invite`, fieldsData.clientToken)
        setShowInviteToChatDld(false)
        setFieldsData({})
    }

    const tableAction = (action, data) => {
        console.log("table action:::::", action, data)

        setClientToken(data['token'])
        setCurrentOnlineID(data['id'])
        setCustomerID(data['customer_id'])

        if(action == "invite"){
            //

            console.log("::::::::::::: invite:::: ", data)

            setShowInviteToChatDld(true)
            setFieldsData({...fieldsData, clientToken: data.token})
        }

        if(action == "messages"){
            setShowCustomerMessagesDld(true)
        }

        if(action == "history"){
            setShowPageViewDld(true)
        }

        if(action == "requests") {
            setShowRecentChatsDld(true)
        }
    }

    const actions = [
        {name: "invite", label:"Invite To Chat"},
        {name: "history", label:"Views History"},
        {name: "requests", label:"Chat History", fn: isShowMenuItem},
        {name: "messages", label:"Client Messages", fn: isShowMenuItem},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    const navigate = useNavigate();

    const activeSiteID =siteData.data && siteData.data[activeSite] && siteData.data[activeSite].id || 0

    let multiActionsData = data.users && data.users[activeSiteID] ? data.users[activeSiteID] : {data: [], rowsCount: 0}

    let filters = {customer_token: clientToken, online_id: currentOnlineID, customer_id: customerID}

    return (
        <>
            <PopUpDialog title={`Page View History`} show={showPageViewDld} hideDialog={setShowPageViewDld}>
               <PageViews filters={filters}  />
            </PopUpDialog>

            <PopUpDialog title={`Invite to Chat`} show={showInviteToChatDld} hideDialog={setShowInviteToChatDld} saveDialogAction={inviteToChat} saveButtonTitle={"Invite"}>
                <textarea rows={`5`} placeholder={`Input invite message`} className={`form-control`} onChange={(event) => fieldsChange(event, "invite_message")}>{fieldsData["invite_message"]}</textarea>
            </PopUpDialog>

            <PopUpDialog title={`Customer Messages`} show={showCustomerMessagesDld} hideDialog={setShowCustomerMessagesDld}>
                <MessagesRepliesViews  filters={filters}  />
            </PopUpDialog>

            <PopUpDialog title={`Recently Chats`} show={showRecentChatsDld} hideDialog={setShowRecentChatsDld}>
                <ChatsViews filters={filters} />
            </PopUpDialog>

            <BasicCRUD
                filters={{response_prefix: "online"}}
                title={`Customers Online (${data.online})`}
                subTitle={`Customers Online`}
                homeURL={"/dashboard"}
                hideGroupActions={true}
                object={object} args={{status: "1"}} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                tableAction={tableAction}
                multiActions={multiActions} data={multiActionsData}>

                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    {siteData.data && Object.keys(siteData.data).map((index) => {

                        var activeClass = ""
                        if (index == activeSite) activeClass = "active"

                        return (
                            <>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 ${activeClass}`}
                                            id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                            role="tab" aria-controls="pills-account" aria-selected="true"
                                            onClick={() => {
                                                const newIndex = parseInt(index) + 1; 
                                                setActiveSite(index); navigate(`/online/${newIndex}`, { replace: true });

                                                socket.emit(`${object}:list`, {token: AppContext.user.token, page: 1, items_count: AppContext.rows_per_page, status: "1", simple_search_text: "", response_prefix: "online"});
                                            }}
                                    >
                                        <i className="ti ti-user-circle me-2 fs-6"></i>
                                        <span className="d-none d-md-block">{siteData.data[index].name} {siteData.data[index].online_count > 0 && <>({siteData.data[index].online_count})</>}</span>
                                    </button>
                                </li>
                            </>
                        )

                    })}
                </ul>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Agent</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Agent</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Message</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  CustomersOnline;
