import React, { useState, useEffect } from 'react';

import Table from './../core/table'
import PageTitle from "./../core/page-title"
import AppContext from "./../../app-context"
import {PUBLIC_URL} from './../../const'

import PopUpDialog from './../core/popup-dialog'
import PageViews from './page-views'

import {socket, requestData, socketUseEffect} from './../../socket'

import {convertSecondToTimeLeft} from './../core/lib/dates'

const CustomersChats = (props) => {
    const users = props.users || [];

    return (
        <>
            <div className="app-chat">
                <ul className="chat-users mb-0 mh-n100 simplebar-scrollable-y" data-simplebar="init">
                    <div className="simplebar-wrapper" style={{margin: "0px"}}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                <div className="simplebar-content-wrapper" tabIndex="0" role="region"
                                     aria-label="scrollable content"
                                     style={{height: "auto", overflow: "hidden scroll"}}>

                                    {users.length == 0 && <div style={{margin: "20px"}}>

                                        <div className="alert alert-warning" role="alert">
                                            <strong>Warning - </strong> There're no active chats
                                        </div>

                                    </div>}

                                    <div className="simplebar-content" style={{padding: "0px"}}>
                                        {users.map((item, index) => {
                                            var unreadCalss = ""
                                            if (item.isread === 0){
                                                unreadCalss = " bg-light-subtle"
                                            }

                                            const num = item.id % 3 + 1
                                            item.avatar = `${PUBLIC_URL}/assets/images/profile/user-${num}.jpg`

                                            var last_message_time = convertSecondToText(item.time)

                                            return (
                                            <li>
                                                <a href="javascript:void(0)"
                                                   className={`px-4 py-3 bg-hover-light-black d-flex align-items-start justify-content-between chat-user ${unreadCalss}`}
                                                   id="chat_user_1" data-user-id="1" onClick={() => {props.setActiveCustomer(index);socket.emit(`recentChats:view`, {id: item.id, user_id: AppContext.user.id, status: 1});}}>
                                                    <div className="d-flex align-items-center">
                          <span className="position-relative">
                            <img src={`${item.avatar}`} alt="user1" width="48" height="48"
                                 className="rounded-circle" />
                            <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                              <span className="visually-hidden">New alerts</span>
                            </span>
                          </span>
                                                        <div className="ms-3 d-inline-block w-75">
                                                            <h6 className="mb-1 fw-semibold chat-title"
                                                                data-username="James Anderson">
                                                                {item.name}
                                                            </h6>
                                                            <span
                                                                className="fs-3 text-truncate text-body-color d-block">{item.whoReplied && item.whoReplied === 1 ? "You: " : ""}{item.lastMessage}</span>
                                                        </div>
                                                    </div>
                                                    <p className="fs-2 mb-0 text-muted">{last_message_time}</p>
                                                </a>
                                            </li>
                                        )})}
                                        <li className="border-bottom my-3"></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simplebar-placeholder" style={{width: "345px", height: "560px"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal" style={{visibility: "hidden"}}>
                        <div className="simplebar-scrollbar" style={{width: "0px", display: "none"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={{visibility: "visible"}}>
                        <div className="simplebar-scrollbar"
                             style={{height: "232px", display: "block", transform: "translate3d(0px, 0px, 0px)"}}></div>
                    </div>
                </ul>
            </div>
        </>
    )
}

const convertSecondToText = (time) => {
    var textTime = ""
    if (time < 60){
        time = Math.floor(time)
        textTime = ` ${time} minutes ago`

        if (time <= 1){
            textTime = ` 1 minute ago`
        }
    }

    time = Math.floor(time / 60)

    if (time > 0 && time < 24){
        textTime = ` ${time} hours ago`
    }

    time = Math.floor(time / 24)
    if (time >0 && time < 30){
        textTime = ` ${time} days ago`
    }

    return textTime
}

const MessagesRepliesViews = (props) => {


    const [agents, setAgents] = useState([]);
    const [data, setData] = useState([]);

    const listeners = [
        //{name: "agents", fn: "forAssign", args: {user_id: AppContext.user.id, status: 1}, saveDataFN: setAgents, interval: 10000},
        {name: "customersMessages", fn: "clientMessages", args: {...props.filters}, saveDataFN: setData/*, interval: 10000*/},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const customers = data.data || []

    const [activeCustomer, setActiveCustomer] = useState(0);
    const [recentSearchText, setRecentSearchText] = useState(localStorage.getItem(`recentChats_simple_search_text`));

    const clientDetails = []

    const activeUser = {
        id: "",
        name: "",
        avatar: `${PUBLIC_URL}/assets/images/profile/user-2.jpg`,
        page: "-",
        viewedCount: "-",
        lastActivity: "-"
    }

    if(customers && customers[activeCustomer]){
        activeUser.id = customers[activeCustomer].id || ""
        activeUser.name = customers[activeCustomer].name || ""
        const userNum = (customers[activeCustomer].id || 0) % 3 + 1
        activeUser.avatar = `${PUBLIC_URL}/assets/images/profile/user-${userNum}.jpg`
        activeUser.page = customers[activeCustomer].URL || ""
        activeUser.lastActivity = convertSecondToText(customers[activeCustomer].time || "-")
        activeUser.viewedCount = customers[activeCustomer].viewCount || "-"

        clientDetails.push({name: "Country", val: customers[activeCustomer].country || "-"})
        clientDetails.push({name: "Client IP Address", val: customers[activeCustomer].ip || "-"},)

        clientDetails.push({name: "Requests Count", val: customers[activeCustomer].chats_cnt || "-"})
        clientDetails.push({name: "Last Requests", val: customers[activeCustomer].last_request || "-"})
        clientDetails.push({name: "Messages Count", val: customers[activeCustomer].messages_cnt || "-"})
        clientDetails.push({name: "Last Message", val: customers[activeCustomer].last_message || "-"})
    }

    const [showUserDetails, setShowUserDetails] = useState(true)

    const handleShowHideDetails = () => {
        if(showUserDetails){
            setShowUserDetails(false);
        }else{
            setShowUserDetails(true);
        }
    }

    const chatMessages = customers && customers[activeCustomer] && customers[activeCustomer].messages && Object.keys(customers[activeCustomer].messages).length > 0 ? customers[activeCustomer].messages : []
    const details = customers[activeCustomer]

    //console.log(" ::: customers ::: ", Object.keys(chatMessages), Object.keys(chatMessages).length, customers)

    const [showPageViewDld, setShowPageViewDld] = useState(false);
    const [showSwitchAgentDld, setShowSwitchAgentDld] = useState(false);
    const [showCustomerMessagesDld, setShowCustomerMessagesDld] = useState(false);
    const [showRecentChatsDld, setShowRecentChatsDld] = useState(false);
    const [clientToken, setClientToken]= useState("")

    const [fieldsData, setFieldsData] = useState({})

    function fieldsChange(event, key){
        setFieldsData({...fieldsData, [key]: event.target.value})
    }

    const switchAgentChat = () => {
        console.log("::::::::::::: invite to chat :::: ", fieldsData)

        alert(`invite ${fieldsData["invite_message"]} - ${fieldsData.clientToken}`)
        socket.emit(`user:invite`, fieldsData.clientToken)
        setShowSwitchAgentDld(false)
        setFieldsData({})
    }

    const onlineID = customers[activeCustomer] && customers[activeCustomer].online_id || 0

   // console.log(" :: chat :: ", customers[activeCustomer])

    return (
        <>
            <div className="card overflow-hidden chat-application">
                <div className="d-flex align-items-center justify-content-between gap-6 m-3 d-lg-none">
                    <button className="btn btn-primary d-flex" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#chat-sidebar" aria-controls="chat-sidebar">
                        <i className="ti ti-menu-2 fs-5"></i>
                    </button>
                    <div className="position-relative w-100">
                        <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                               placeholder="Search Contact" value={recentSearchText} onChange={(event) => setRecentSearchText(event.target.value)} onKeyDown={(e) => {if (e.key === 'Enter') {
                            localStorage.setItem('recentChats_simple_search_text', e.target.value);
                            requestData('recentChats', {user_id: AppContext.user.id, status: 1});
                        }}} />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="w-30 d-none d-lg-block border-end user-chat-box">
                        <CustomersChats users={customers} setActiveCustomer={setActiveCustomer} />
                    </div>
                    <div className="w-70 w-xs-100 chat-container">
                        {customers.length > 0 && <div className="chat-box-inner-part h-100">
                            <div className="chat-not-selected h-100 d-none">
                                <div className="d-flex align-items-center justify-content-center h-100 p-5">
                                    <div className="text-center">
                        <span className="text-primary">
                          <i className="ti ti-message-dots fs-10"></i>
                        </span>
                                        <h6 className="mt-2">Open chat from the list</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="chatting-box d-block">
                                <div className={showUserDetails && !props.hideDetails ? "d-flex parent-chat-box":"d-flex parent-chat-box app-chat-right"}>
                                    <div className="chat-box w-xs-100">
                                        <div className="chat-box-inner p-9" data-simplebar="init">
                                            <div className="simplebar-wrapper" style={{margin: "-20px"}}>
                                                <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer"></div>
                                                </div>
                                                <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                                        <div className="simplebar-content-wrapper" tabIndex="0"
                                                             role="region" aria-label="scrollable content"
                                                             style={{height: "100%", overflow: "hidden"}}>
                                                            <div className="simplebar-content" style={{padding: "20px"}}>
                                                                <div className="chat-list chat active-chat" data-user-id="1">
                                                                    <h6 className="fs-2 text-muted">{details.name}</h6>
                                                                    <div className="p-2 text-bg-light rounded-1 d-inline-block text-dark fs-3" style={{marginBottom: "30px"}}>
                                                                        <b>Subject:</b> {details.subject}
                                                                        <hr />
                                                                        <b>Question:</b><br />
                                                                        {details.message}
                                                                    </div>

                                                                    {Object.keys(chatMessages).map(i => {
                                                                        const item = chatMessages[i]
                                                                        //return null

                                                                        var locClass="start"
                                                                        if (item.whoReplied == 2){
                                                                            locClass="end"
                                                                        }

                                                                        var spent = convertSecondToTimeLeft(item.time)


                                                                        return (
                                                                            <div className={`hstack gap-3 align-items-start mb-7 justify-content-${locClass}`}>
                                                                                <img src={`${activeUser.avatar}`} alt="user8" width="40" height="40" className="rounded-circle" />
                                                                                <div>
                                                                                    <h6 className="fs-2 text-muted">
                                                                                        {item.name}, {spent}
                                                                                    </h6>
                                                                                    <div className="p-2 text-bg-light rounded-1 d-inline-block text-dark fs-3">
                                                                                        {item.message}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="simplebar-placeholder"
                                                     style={{width: "506px", height: "577px"}}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-horizontal"
                                                 style={{visibility: "hidden"}}>
                                                <div className="simplebar-scrollbar"
                                                     style={{width: "0px", display: "none"}}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-vertical"
                                                 style={{visibility: "hidden"}}>
                                                <div className="simplebar-scrollbar"
                                                     style={{height: "0px", display: "none"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
export  default  MessagesRepliesViews;
