import React, { useState } from 'react';
import {Link} from "react-router-dom";

import BasicCRUD from './../core/basic-crud'
import PopUpDialog from './../core/popup-dialog'

const Sites = (props) => {
    const object = "sites"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const  columns = [
        {label:"Name", key: "name"},
        {label:"URL",  key: "url"},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const fields = [
        {
            type: "tab",//tab
            name: "",
            image: "",
            view: "",
            fields: [
                [
                    {label: "Site Name", key: "name"},
                ],
                [
                    {label: "URL", key: "url"},
                ]
            ]
        },
    ]

    const [showInstallCodeDld, setInstallCodeDld] = useState(false);

    const actions = [
        {name: "install_code", label:"Install Code"},
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    const tableAction = (action, data) => {
        if(action == "install_code"){
            //socket.emit(`customersMessages:getMessagesByCustomer`, {...data, customer_token: data['token']})
            setInstallCodeDld(true)
        }
    }

    function fieldsChange(event, key){
        setFieldsData({...fieldsData, [key]: event.target.value})
    }

    const inviteToChat = () => {
        console.log("::::::::::::: invite to chat :::: ", fieldsData)

        alert(`invite ${fieldsData["invite_message"]} - ${fieldsData.clientToken}`)
        //socket.emit(`user:invite`, fieldsData.clientToken)
        setInstallCodeDld(false)
        setFieldsData({})
    }

    return (
        <>
            <PopUpDialog title={`Install Code`} show={showInstallCodeDld} hideDialog={setInstallCodeDld} saveDialogAction={inviteToChat} saveButtonTitle={"Invite"}>
                <textarea rows={`5`} placeholder={`Input invite message`} className={`form-control`} onChange={(event) => fieldsChange(event, "invite_message")}>{fieldsData["invite_message"]}</textarea>
            </PopUpDialog>

            <BasicCRUD
                title={"Settings (`Sites`)"}
                subTitle={"Sites"}
                homeURL={"/dashboard"}
                addTitle="Add Site" editTitle={"Edit Site"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                tableAction={tableAction}
                ownHandler={true}
                multiActions={multiActions}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Site</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Site</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Site</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to="/sites" className="sidebar-link" data-text="Sites" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 active`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Sites</span>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to="/departments" className="sidebar-link" data-text="Dashboard" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Departments</span>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to="/abbreviations" className="sidebar-link" data-text="Dashboard" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Abbreviations</span>
                            </button>
                        </Link>
                    </li>
                </ul>

            </BasicCRUD>
        </>
    )
}
export  default  Sites;
