import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import AppContext from "./../../app-context"
import { socket, socketUseEffect } from './../../socket';

import {PUBLIC_URL} from './../../const'

const LeftMenu = (props) => {
    //const [messagesCount, setMessagesCount] = useState(0)

    //useEffect(() => {
    //    socket.on("chats:new-messages", (data) => {
    //        setMessagesCount(data)
    //    })
    //})

    const [counters, setCounters] = useState(false);

    console.log(" :: counters :: ", counters)

    const listeners = [
        {name: "general", fn: "Counters", args: {user_id: AppContext.user.id}, saveDataFN: setCounters, interval: 10000},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    //useEffect( () => listener ({name:"general", fn: "Counters"}, setCounters), []);

    return (
        <>
            <div className="brand-logo d-flex align-items-center justify-content-between">
                <a href="../index.html" className="text-nowrap logo-img">
                    <img src={`${PUBLIC_URL}/assets/images/logos/dark-logo.svg`} className="dark-logo" alt="Logo-Dark" />
                </a>
                <a
                    href="javascript:void(0)"
                    className="sidebartoggler ms-auto text-decoration-none fs-5 d-block d-xl-none"
                >
                    <i className="ti ti-x"></i>
                </a>
            </div>
            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                <ul id="sidebarnav">

                    <li className="nav-small-cap">
                        <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                        <span className="hide-menu">Home</span>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/dashboard" className="sidebar-link" data-text="Dashboard" >
                            <span><i className="ti ti-aperture"></i></span><span className="hide-menu">Dasboard</span>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/online" className="sidebar-link justify-content-between" data-text="Dashboard" >
                            <div className="d-flex align-items-center gap-3">
                                  <span className="d-flex">
                                    <i className="ti ti-aperture"></i>
                                  </span>
                                <span className="hide-menu">Online</span>
                            </div>
                            <div className="hide-menu">
                                {counters.online && counters.online > 0 ? <span className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center rounded-pill fs-2">{counters.online}</span> : null }
                            </div>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/sessions" className="sidebar-link justify-content-between" data-text="Dashboard" >
                            <div className="d-flex align-items-center gap-3">
                                  <span className="d-flex">
                                    <i className="ti ti-aperture"></i>
                                  </span>
                                <span className="hide-menu">Old Sessions</span>
                            </div>
                            <div className="hide-menu">
                            </div>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/chat-requests" className="sidebar-link justify-content-between" data-text="Dashboard" >
                            <div className="d-flex align-items-center gap-3">
                                  <span className="d-flex">
                                    <i className="ti ti-award"></i>
                                  </span>
                                <span className="hide-menu">Chats</span>
                            </div>
                            <div className="hide-menu">
                                {counters.chats && counters.chats > 0 ? <span className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center rounded-pill fs-2">{counters.chats}</span> : null }
                            </div>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/history" className="sidebar-link" data-text="Dashboard" >
                            <span><i className="ti ti-aperture"></i></span><span className="hide-menu">History</span>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/messages" className="sidebar-link justify-content-between" data-text="Dashboard" >
                            <div className="d-flex align-items-center gap-3">
                                  <span className="d-flex">
                                    <i className="ti ti-aperture"></i>
                                  </span>
                                <span className="hide-menu">Messages</span>
                            </div>
                            <div className="hide-menu">
                                {counters.messages && counters.messages > 0 ? <span className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center rounded-pill fs-2">{counters.messages}</span> : null }
                            </div>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/sites" className="sidebar-link" data-text="Settings" >
                            <span><i className="ti ti-aperture"></i></span><span className="hide-menu">Settings</span>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link to="/agents" className="sidebar-link" data-text="Dashboard" >
                            <span><i className="ti ti-aperture"></i></span><span className="hide-menu">Agents</span>
                        </Link>
                    </li>

                </ul>
            </nav>
            <div className="fixed-profile p-3 mx-4 mb-2 bg-secondary-subtle rounded mt-3">
                <div className="hstack gap-3">
                    <div className="john-img">
                        <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`} className="rounded-circle" width="40" height="40" alt="" />
                    </div>
                    <div className="john-title">
                        <h6 className="mb-0 fs-4 fw-semibold">{AppContext.user.name}</h6>
                        <span className="fs-2">{AppContext.user.role}</span>
                    </div>
                    <button
                        className="border-0 bg-transparent text-primary ms-auto"
                        tabIndex="0"
                        type="button"
                        aria-label="logout"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="logout"
                    >
                        <i className="ti ti-power fs-6"></i>
                    </button>
                </div>
            </div>
        </>

    )
}

const LeftMenuOld = () => {
    return (
        <>
            <div className="left_menu">
                <div className="workspace">
                    <img className="workspace__avatar" src="img/user.png" alt="" />
                    <ul className="dropdown-menu">
                        <li className="dropdown-menu__title">Workspace</li>
                        <li className="dropdown-menu__item">Personal Workspace</li>
                        <li className="dropdown-menu__item">Personal Workspace2</li>
                    </ul>
                </div>
                <Link to="/dashboard" className="left_menu__item 1 tooltip" data-text="Dashboard" ><i className="fa-solid fa-house"></i></Link>
                <Link to="/chat-requests" className="left_menu__item tooltip 1" data-text="Chat Requests"><i className="fa-solid fa-message"></i></Link>
                <Link to="/history" className="left_menu__item tooltip 1" data-text="History"><i className="fa-solid fa-history"></i></Link>
                <Link to="/messages" className="left_menu__item tooltip 1" data-text="Customer Messages"><i className="fa-solid fa-history"></i></Link>
                <Link to="/departments" className="left_menu__item tooltip 1" data-text="Departments"><i className="fa-solid fa-building"></i></Link>
                <Link to="/abbreviations" className="left_menu__item tooltip 1" data-text="Abbreviations"><i className="fa-solid fa-history"></i></Link>
                <Link to="/agents" className="left_menu__item tooltip 1" data-text="Agents"><i className="fa-solid fa-users"></i></Link>

                <a href="/dashboard" className="left_menu__item 1 tooltip" data-text="Dashboard"><i className="fa-solid fa-house"></i></a>
                <a href="/history" className="left_menu__item tooltip 1" data-text="History"><i className="fa-solid fa-history"></i></a>
                <a href="/customers-requests" className="left_menu__item tooltip active" data-text="Customers Requests"><i className="fa-solid fa-message"></i></a>
                <a href="/statistics" className="left_menu__item tooltip 1" data-text="Support"><i className="fa-solid fa-bar-chart"></i></a>
                <a href="/departments" className="left_menu__item tooltip 1" data-text="Departments"><i className="fa-solid fa-building"></i></a>
                <a href="/users" className="left_menu__item tooltip 1" data-text="Users"><i className="fa-solid fa-users"></i></a>
                <a href="/setup" className="left_menu__item tooltip" data-text="Settings"><i className="fa-solid fa-gear"></i></a>
            </div>
        </>
    )
}

export default LeftMenu
