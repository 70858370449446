import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router'
import { Route, Routes, useParams } from 'react-router-dom';

const RenderOptions = (props) => {
    const response = props.response
    const fieldsData = props.fieldsData
    const fieldsChange = props.fieldsChange

    return (
        <>
            {props.fields ? props.fields.map(item => {
                var columns = 12 / item.length;
                //columns = item.columns || columns

                return (
                    <>
                        <div className={`row ${props.align && props.align == "center" ? "justify-content-center" : null}`}>
                            {item.map(f => {

                                const err = response.err && response.err[f.key] ? response.err[f.key] : ""
                                const fieldType = f.type || "input"
                                //console.log("err: ", err)

                                const newColumns = f.columns || columns

                                const errClass = err !== "" ? "is-invalid" : ""

                                const CustomField = f.fn || null

                                return (
                                    <>
                                        <div className={`col-md-${newColumns} d-flex`}>
                                                {fieldType == "custom" && CustomField ? (
                                                    <>
                                                        <CustomField />
                                                    </>
                                                ) : null}

                                                {fieldType == "group" ? (
                                                    <>
                                                        <div className="card w-100 position-relative overflow-hidden">
                                                            <div className="card-body p-4">
                                                                <h5 className="card-title fw-semibold">{f.label}</h5>
                                                                <p className="card-subtitle mb-4">{f.subLabel}</p>
                                                                <form>
                                                                    <RenderOptions fields={f.fields} response={response} fieldsData={fieldsData} fieldsChange={fieldsChange} enterApply={props.enterApply} />
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}

                                            {fieldType == "switch" ? (
                                                    <>
                                                        <div style={{width: "100%"}}>
                                                        <div
                                                            className="d-flex align-items-center justify-content-between mb-4">
                                                            <div className="d-flex align-items-center gap-3">
                                                                <div className="text-bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                                                    <i className={`ti ${f.icon} text-dark d-block fs-7`} width="22" height="22"></i>
                                                                </div>
                                                                <div>
                                                                    <h5 className="fs-4 fw-semibold">{f.label}</h5>
                                                                    <p className="mb-0">{f.subLabel}</p>
                                                                </div>
                                                            </div>
                                                            <div className="form-check form-switch mb-0">
                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </>
                                                    ): null
                                            }
                                                {fieldType == "input" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.showLabel && f.showLabel == "left" && <label className="form-label fw-semibold col-sm-3 col-form-label">{f.label}</label>}
                                                            {f.showLabel && f.showLabel == "top" && <label htmlFor="fname1" className="control-label col-form-label">{f.label}</label>}
                                                            {f.beforeField && <>{f.beforeField}</>}
                                                            <input style={{width: "100%", ...f.styleField}} autoComplete="" type="text" className={`form-control ${f.classField} ${errClass}`} placeholder={`${f.label}`}  value={fieldsData[f.key]} onChange={(event) => fieldsChange(event, f.key)} onKeyPress={props.enterApply} />
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>

                                                    </>
                                                ) : null}
                                                {fieldType == "password" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            <input style={{width: "100%"}} autoComplete="" type="password" className={`form-control ${errClass}`} placeholder={`${f.label}`}  value={fieldsData[f.key]} onChange={(event) => fieldsChange(event, f.key)} />
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {fieldType == "selectbox" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.label && f.label != "" && <label className="form-label">{f.label}</label>}
                                                            <select style={{width: "100%"}} className="form-select" onChange={(event) => {
                                                                //fieldsChange(event, f.key);
                                                                //props.enterApply({key: "Enter"})

                                                                fieldsChange(event, f.key).then(result => {
                                                                    console.log(" //  - - - cool select result - - - // ", result)
                                                                    props.enterApply({key: "Enter", data: {...result}})
                                                                })
                                                            }}>
                                                                {f.data.map((item) => {
                                                                    // console.log("::: item ::: - >>>> ", fieldsData[f.key][item.id])
                                                                    //console.log("fieldsData ::: ", fieldsData);
                                                                    var checked = fieldsData && fieldsData[f.key] == item.id
                                                                    return (
                                                                        <option value={item.id} selected={checked}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {fieldType == "textarea" ? (
                                                    <>
                                                        <div className="mb-3"  style={{width: "100%"}}>
                                                            <textarea id="text2-3" rows={`${f.rows || 5}`} placeholder={`${f.label}`} className={`form-control ${errClass}`} onChange={(event) => fieldsChange(event, f.key)}>{fieldsData[f.key]}</textarea>
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {fieldType == "checkbox" ? (() => {
                                                    //var options = fieldsData[`${f.key}`] || []
                                                    //console.log("::: item ::: - >>>> ", options, f.key)
                                                    //let listOption = []
                                                    //for(var o=0;o<options.length;o++){
                                                    //    listOption.push(options[o].id)
                                                    //}

                                                    //console.log(" ::: options ::: ", listOption)

                                                    //return null

                                                    return (
                                                        <>
                                                            <div className="mb-3 contact-location" style={{...f.styleField}}>
                                                                {f.label && f.label != "" && <p>{f.label}:</p>}
                                                                
                                                                {f.data.map((item) => {
                                                                    // console.log("::: item ::: - >>>> ", fieldsData[f.key][item.id])
                                                                    //console.log("fieldsData ::: ", fieldsData);
                                                                    var checked = fieldsData && fieldsData[f.key] && fieldsData[f.key].includes(item.id)
                                                                    return (
                                                                        <label class={`col-md-12`}>
                                                                            <input type="checkbox" style={{marginRight: "10px"}} checked={checked} onChange={(event) => {
                                                                                fieldsChange(event, f.key, item.id).then(result => {
                                                                                    //console.log(" //  - - - cool result - - - // ", result)
                                                                                    props.enterApply({key: "Enter", data: {...result}})
                                                                                })
                                                                            }} />
                                                                            {item.name}
                                                                        </label>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    )})() : null}
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </>
                )
            }) : null}
        </>
    )
}

const RenderFormFields = (props) => {
    const { tab } = useParams();
    let curTab = tab - 1
    
    //console.log(" ### tab ### ", tab)
    
    const tabs = props.fields || []
    const fieldsData = props.fieldsData || {}
    const fieldsChange = props.fieldsChange

    const [active, setActive] = useState(0)

    const response = props.response
    //console.log("response ------------------------------------")
    //console.log(response)


    let style = {}
    if (props.isForm) {
        style = {marginBottom: "40px"}
    }

    const navigate = useNavigate();

    let borderClass = "bordered"
    if (props.hideBorders){
        borderClass = ""
    }
    
    return (
        <>
            {tabs && tabs.length > 1 ? (
            <ul className="nav nav-tabs" role="tablist">
                {tabs.map((item, index) => {
                    var classActive = ""
    
                    if (!props.isForm){
                        curTab = active
                    }
                    
                    if (curTab == index){
                        //console.log(" ### active ### ", index)
                        classActive = "active"
                    }

                    /* calculate errors count per page */

                    let errorNumber = 0
                    if (response.err){
                        item.fields.forEach((fieldsGroup) => {
                            fieldsGroup.forEach((field) => {
                                const err = response.err[field.key] || ""
                                if (err !== ""){
                                    errorNumber++
                                }
                            })
                        })
                    }

                    return (
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link d-flex ${classActive}`} data-bs-toggle="tab" href="javascript:void(0)" role="tab" aria-selected="false" tabIndex="-1" onClick={() => {setActive(index); if (props.isForm){navigate(`/my-profile/${index + 1}`, { replace: true });}}}>
                                <span><i className={`${item.image}`}></i></span>
                                <span className="d-none d-md-block ms-2">{item.name}{errorNumber>0 ? <> <b style={{color:"red"}}>({errorNumber})</b> </> : null}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>) : null}


            <div className={`tab-content ${borderClass} mt-2`} style={style}>
            {tabs ? tabs.map((tab, index) =>{
                var classActive = ""
                
                if (!props.isForm){
                    curTab = active
                }
                
                if (curTab == index){
                    classActive = "active show"
                }

                return (
                    <>
                        <div className={`tab-pane p-3 ${classActive}`} id="navpill-11" role="tabpanel">
                            <RenderOptions fields={tab.fields} align={tab.align} response={response} fieldsData={fieldsData} fieldsChange={fieldsChange} enterApply={props.enterApply} />

                            {props.isForm && <div className="col-12">
                                <div className="d-flex align-items-center justify-content-end gap-6">
                                    <button className="btn btn-primary" onClick={props.saveAction || (() => {console.log("please configure save action")})}>Save</button>
                                    <button className="btn bg-danger-subtle text-danger" onClick={props.cancelAction || (() => {console.log("please configure cancel action")})}>Cancel</button>
                                </div>
                            </div>}

                        </div>
                    </>
                )

            }) : null}
            </div>
        </>
    )
}

export default RenderFormFields
