import React, { useState, useEffect } from 'react';

import Table from './../core/table'
import PageTitle from "./../core/page-title"
import AppContext from "./../../app-context"
import {PUBLIC_URL} from './../../const'

import PopUpDialog from './../core/popup-dialog'
import PageViews from './page-views'

import ChatsViews from './chatsViews'
import MessagesRepliesViews from './message-repliesView'

import {socket, requestData, socketUseEffect} from './../../socket'

const CustomersChats = (props) => {
    const users = props.users || [];

    return (
        <>
            <div className="app-chat">
                <ul className="chat-users mb-0 mh-n100 simplebar-scrollable-y" data-simplebar="init">
                    <div className="simplebar-wrapper" style={{margin: "0px"}}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                <div className="simplebar-content-wrapper" tabIndex="0" role="region"
                                     aria-label="scrollable content"
                                     style={{height: "auto", overflow: "hidden scroll"}}>

                                    {users.length == 0 && <div style={{margin: "20px"}}>

                                        <div className="alert alert-warning" role="alert">
                                            <strong>Warning - </strong> There're no active chats
                                        </div>

                                    </div>}

                                    <div className="simplebar-content" style={{padding: "0px"}}>
                                        {users.map((item, index) => {
                                            var unreadCalss = ""
                                            if (item.isread === 0){
                                                unreadCalss = " bg-light-subtle"
                                            }

                                            const num = item.id % 3 + 1
                                            item.avatar = `${PUBLIC_URL}/assets/images/profile/user-${num}.jpg`

                                            var last_message_time = convertSecondToText(item.time)

                                            return (
                                            <li>
                                                <a href="javascript:void(0)"
                                                   className={`px-4 py-3 bg-hover-light-black d-flex align-items-start justify-content-between chat-user ${unreadCalss}`}
                                                   id="chat_user_1" data-user-id="1" onClick={() => {props.setActiveCustomer(index);socket.emit(`recentChats:view`, {id: item.id, user_id: AppContext.user.id, status: 1});}}>
                                                    <div className="d-flex align-items-center">
                          <span className="position-relative">
                            <img src={`${item.avatar}`} alt="user1" width="48" height="48"
                                 className="rounded-circle" />
                            <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                              <span className="visually-hidden">New alerts</span>
                            </span>
                          </span>
                                                        <div className="ms-3 d-inline-block w-75">
                                                            <h6 className="mb-1 fw-semibold chat-title"
                                                                data-username="James Anderson">
                                                                {item.name}
                                                            </h6>
                                                            <span
                                                                className="fs-3 text-truncate text-body-color d-block">{item.whoReplied && item.whoReplied === 1 ? "You: " : ""}{item.lastMessage}</span>
                                                        </div>
                                                    </div>
                                                    <p className="fs-2 mb-0 text-muted">{last_message_time}</p>
                                                </a>
                                            </li>
                                        )})}
                                        <li className="border-bottom my-3"></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simplebar-placeholder" style={{width: "345px", height: "560px"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal" style={{visibility: "hidden"}}>
                        <div className="simplebar-scrollbar" style={{width: "0px", display: "none"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={{visibility: "visible"}}>
                        <div className="simplebar-scrollbar"
                             style={{height: "232px", display: "block", transform: "translate3d(0px, 0px, 0px)"}}></div>
                    </div>
                </ul>
            </div>
        </>
    )
}

const convertSecondToText = (time) => {
    var textTime = ""
    if (time < 60){
        time = Math.floor(time)
        textTime = ` ${time} minutes ago`

        if (time <= 1){
            textTime = ` 1 minute ago`
        }
    }

    time = Math.floor(time / 60)

    if (time > 0 && time < 24){
        textTime = ` ${time} hours ago`
    }

    time = Math.floor(time / 24)
    if (time >0 && time < 30){
        textTime = ` ${time} days ago`
    }

    return textTime
}

const ChatRequests = (props) => {
    /*
    const  columns = [
        {label:"Customer", key: "name"},
        {label:"Agent", key: "agent_name"},
        {label:"Question", key: "question"},
        {label:"Status", key: "status"},
        {label:"Updated", key: "updated_at"},
    ]
    */
/*
    const customers = [
        {name: "Artem Zabolotniy", avatar: `${PUBLIC_URL}/assets/images/profile/user-1.jpg`, lastMessage: "How are you?", whoReplied:1, unread: 0},
        {name: "Mark Luchko", avatar: `${PUBLIC_URL}/assets/images/profile/user-2.jpg`, lastMessage: "How are you?", whoReplied:2, unread: 1},
        {name: "Sergey Makovoz", avatar: `${PUBLIC_URL}/assets/images/profile/user-3.jpg`, lastMessage: "How are you?", whoReplied:2,unread: 1}
    ]
*/

    const [agents, setAgents] = useState([]);
    const [data, setData] = useState([]);

    const listeners = [
        {name: "agents", fn: "forAssign", args: {user_id: AppContext.user.id, status: 1}, saveDataFN: setAgents, interval: 10000},
        {name: "recentChats", fn: "list", args: {user_id: AppContext.user.id, status: 1}, saveDataFN: setData, interval: 10000},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const customers = data.data || []

    const [activeCustomer, setActiveCustomer] = useState(0);
    const [recentSearchText, setRecentSearchText] = useState(localStorage.getItem(`recentChats_simple_search_text`));

    const clientDetails = []

    const activeUser = {
        id: "",
        name: "",
        avatar: `${PUBLIC_URL}/assets/images/profile/user-2.jpg`,
        page: "-",
        viewedCount: "-",
        lastActivity: "-"
    }

    if(customers && customers[activeCustomer]){
        activeUser.id = customers[activeCustomer].id || ""
        activeUser.name = customers[activeCustomer].name || ""
        const userNum = (customers[activeCustomer].id || 0) % 3 + 1
        activeUser.avatar = `${PUBLIC_URL}/assets/images/profile/user-${userNum}.jpg`
        activeUser.page = customers[activeCustomer].URL || ""
        activeUser.lastActivity = convertSecondToText(customers[activeCustomer].time || "-")
        activeUser.viewedCount = customers[activeCustomer].viewCount || "-"

        clientDetails.push({name: "Country", val: customers[activeCustomer].country || "-"})
        clientDetails.push({name: "Client IP Address", val: customers[activeCustomer].ip || "-"},)

        clientDetails.push({name: "Requests Count", val: customers[activeCustomer].chats_cnt || "-"})
        clientDetails.push({name: "Last Requests", val: customers[activeCustomer].last_request || "-"})
        clientDetails.push({name: "Messages Count", val: customers[activeCustomer].messages_cnt || "-"})
        clientDetails.push({name: "Last Message", val: customers[activeCustomer].last_message || "-"})
    }

    const [showUserDetails, setShowUserDetails] = useState(true)

    const handleShowHideDetails = () => {
        if(showUserDetails){
            setShowUserDetails(false);
        }else{
            setShowUserDetails(true);
        }
    }

    const chatMessages = customers && customers[activeCustomer] && customers[activeCustomer].messages && Object.keys(customers[activeCustomer].messages).length > 0 ? customers[activeCustomer].messages : []
    //console.log(" ::: customers ::: ", Object.keys(chatMessages), Object.keys(chatMessages).length, customers)

    const [showPageViewDld, setShowPageViewDld] = useState(false);
    const [showSwitchAgentDld, setShowSwitchAgentDld] = useState(false);
    const [showCustomerMessagesDld, setShowCustomerMessagesDld] = useState(false);
    const [showRecentChatsDld, setShowRecentChatsDld] = useState(false);
    const [clientToken, setClientToken]= useState("")

    const [fieldsData, setFieldsData] = useState({})

    function fieldsChange(event, key){
        setFieldsData({...fieldsData, [key]: event.target.value})
    }

    const switchAgentChat = () => {
        console.log("::::::::::::: invite to chat :::: ", fieldsData)

        alert(`invite ${fieldsData["invite_message"]} - ${fieldsData.clientToken}`)
        socket.emit(`user:invite`, fieldsData.clientToken)
        setShowSwitchAgentDld(false)
        setFieldsData({})
    }

    const onlineID = customers[activeCustomer] && customers[activeCustomer].online_id || 0

   // console.log(" :: chat :: ", customers[activeCustomer])

    return (
        <>
            {!props.hideTitle && <PageTitle title={"Chats"} homeUrl={"/dashboard"} />}

            <PopUpDialog title={`Page View History`} show={showPageViewDld} hideDialog={setShowPageViewDld}>
                <PageViews filters={{customer_token: clientToken, id: onlineID}} />
            </PopUpDialog>

            <PopUpDialog title={`Switch Agent`} show={showSwitchAgentDld} hideDialog={setShowSwitchAgentDld} saveDialogAction={switchAgentChat} saveButtonTitle={"Swich User"}>
                <div className="row null">
                    <div className="col-md-12 d-flex">
                        <div className="form-group mb-4">
                            <label htmlFor="fname1" className="control-label col-form-label">Agent</label>
                            <select className="form-select" id="exampleFormControlSelect1" onChange={(event) => fieldsChange(event, "agent")}>
                                {agents.data && agents.data.map((item) => {
                                   return (
                                       <>
                                           <option value={item.id}>{item.name}</option>
                                       </>
                                   )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row null">
                    <div className="col-md-12 d-flex">
                        <div className="mb-3" style={{width: "100%"}}>
                            <label htmlFor="fname1" className="control-label col-form-label">Public Message</label>
                            <textarea rows={`5`} placeholder={`Input public message`} className={`form-control`} onChange={(event) => fieldsChange(event, "public_message")}>{fieldsData["public_message"]}</textarea>
                        </div>
                    </div>
                </div>
                <div className="row null">
                    <div className="col-md-12 d-flex">
                        <div className="mb-3" style={{width: "100%"}}>
                            <label htmlFor="fname1" className="control-label col-form-label">Private Message</label>
                            <textarea rows={`5`} placeholder={`Input private message`} className={`form-control`} onChange={(event) => fieldsChange(event, "private_message")}>{fieldsData["private_message"]}</textarea>
                        </div>
                    </div>
                </div>
            </PopUpDialog>

            <PopUpDialog title={`Customer Messages`} show={showCustomerMessagesDld} hideDialog={setShowCustomerMessagesDld}>
                <MessagesRepliesViews  disableReply={true} data={props.getMessagesByCustomer || {data: [], rowsCount: 0}} />
            </PopUpDialog>

            <PopUpDialog title={`Recently Chats`} show={showRecentChatsDld} hideDialog={setShowRecentChatsDld}>
                <ChatsViews  hideDetails={true} disableReply={true} />
            </PopUpDialog>

            <div className="card overflow-hidden chat-application">
                <div className="d-flex align-items-center justify-content-between gap-6 m-3 d-lg-none">
                    <button className="btn btn-primary d-flex" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#chat-sidebar" aria-controls="chat-sidebar">
                        <i className="ti ti-menu-2 fs-5"></i>
                    </button>
                    <div className="position-relative w-100">
                        <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                               placeholder="Search Contact" value={recentSearchText} onChange={(event) => setRecentSearchText(event.target.value)} onKeyDown={(e) => {if (e.key === 'Enter') {
                            localStorage.setItem('recentChats_simple_search_text', e.target.value);
                            requestData('recentChats', {user_id: AppContext.user.id, status: 1});
                        }}} />
                            <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="w-30 d-none d-lg-block border-end user-chat-box">
                        {!props.hideAvatar && <div className="px-4 pt-9 pb-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="position-relative">
                                        <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`} alt="user1" width="54" height="54" className="rounded-circle" />
                        <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="fw-semibold mb-2">{AppContext.user.name}</h6>
                                        <p className="mb-0 fs-2">{AppContext.user.role}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative mb-4">
                                <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                                       placeholder="Search Contact" value={recentSearchText} onChange={(event) => setRecentSearchText(event.target.value)} onKeyDown={(e) => {if (e.key === 'Enter') {
                                    localStorage.setItem('recentChats_simple_search_text', e.target.value);
                                    requestData('recentChats', {user_id: AppContext.user.id, status: 1});
                                }}} />
                                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                            </div>
                            <div className="dropdown">
                                <a className="text-muted fw-semibold d-flex align-items-center"
                                   href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    Recent Chats<i className="ti ti-chevron-down ms-1 fs-5"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0)">Sort by time</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item border-bottom" href="javascript:void(0)">Sort by
                                            Unread</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0)">Hide favourites</a>
                                    </li>
                                </ul>
                            </div>
                        </div>}
                        <CustomersChats users={customers} setActiveCustomer={setActiveCustomer} />
                    </div>
                    <div className="w-70 w-xs-100 chat-container">
                        {customers.length == 0 && <div style={{margin: "20px"}}>

                                <div className="alert alert-warning" role="alert">
                                    <strong>Warning - </strong> There're no active chats
                                </div>

                        </div>}
                        {customers.length > 0 && <div className="chat-box-inner-part h-100">
                            <div className="chat-not-selected h-100 d-none">
                                <div className="d-flex align-items-center justify-content-center h-100 p-5">
                                    <div className="text-center">
                        <span className="text-primary">
                          <i className="ti ti-message-dots fs-10"></i>
                        </span>
                                        <h6 className="mt-2">Open chat from the list</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="chatting-box d-block">
                                {!props.hideAvatar && <div
                                    className="p-9 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
                                    <div className="hstack gap-3 current-chat-user-name">
                                        <div className="position-relative">
                                            <img src={`${PUBLIC_URL}/assets/images/profile/user-2.jpg`} alt="user1" width="48" height="48" className="rounded-circle"/>
                          <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                            <span className="visually-hidden">New alerts</span>
                          </span>
                                        </div>
                                        <div>
                                            <h6 className="mb-1 name fw-semibold">{activeUser.name}</h6>
                                            <p className="mb-0"><b>Active page:</b> {activeUser.page} | <b>View Count:</b> {activeUser.viewedCount} | <b>Last Activity:</b> {activeUser.lastActivity}</p>
                                        </div>
                                    </div>
                                    {!props.hideDetails && customers.length > 0 && <ul className="list-unstyled mb-0 d-flex align-items-center">
                                        <li>
                                            <a className="chat-menu text-dark px-2 fs-7 bg-hover-primary nav-icon-hover position-relative z-index-5"
                                               href="javascript:void(0)" onClick={handleShowHideDetails}>
                                                <i className="ti ti-menu-2"></i>
                                            </a>
                                        </li>
                                    </ul>}
                                </div>}
                                <div className={showUserDetails && !props.hideDetails ? "d-flex parent-chat-box":"d-flex parent-chat-box app-chat-right"}>
                                    <div className="chat-box w-xs-100">
                                        <div className="chat-box-inner p-9" data-simplebar="init">
                                            <div className="simplebar-wrapper" style={{margin: "-20px"}}>
                                                <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer"></div>
                                                </div>
                                                <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                                        <div className="simplebar-content-wrapper" tabIndex="0"
                                                             role="region" aria-label="scrollable content"
                                                             style={{height: "100%", overflow: "hidden"}}>
                                                            <div className="simplebar-content" style={{padding: "20px"}}>
                                                                <div className="chat-list chat active-chat" data-user-id="1">
                                                                    {Object.keys(chatMessages).map(i => {
                                                                        const item = chatMessages[i]
                                                                        //return null

                                                                        var locClass="start"
                                                                        if (item.whoReplied == 2){
                                                                            locClass="end"
                                                                        }

                                                                        var spent = convertSecondToText(item.time)


                                                                        return (
                                                                            <div className={`hstack gap-3 align-items-start mb-7 justify-content-${locClass}`}>
                                                                                <img src={`${activeUser.avatar}`} alt="user8" width="40" height="40" className="rounded-circle" />
                                                                                <div>
                                                                                    <h6 className="fs-2 text-muted">
                                                                                        {item.name}, {spent}
                                                                                    </h6>
                                                                                    <div className="p-2 text-bg-light rounded-1 d-inline-block text-dark fs-3">
                                                                                        {item.message}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="simplebar-placeholder"
                                                     style={{width: "506px", height: "577px"}}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-horizontal"
                                                 style={{visibility: "hidden"}}>
                                                <div className="simplebar-scrollbar"
                                                     style={{width: "0px", display: "none"}}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-vertical"
                                                 style={{visibility: "hidden"}}>
                                                <div className="simplebar-scrollbar"
                                                     style={{height: "0px", display: "none"}}></div>
                                            </div>
                                        </div>
                                        {!props.disableReply && <div className="px-9 py-6 border-top chat-send-message-footer">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center gap-2 w-85">
                                                    <a className="position-relative nav-icon-hover z-index-5"
                                                       href="javascript:void(0)">
                                                        <i className="ti ti-mood-smile text-dark bg-hover-primary fs-7"></i></a>
                                                    <input type="text"
                                                           className="form-control message-type-box text-muted border-0 p-0 ms-2"
                                                           placeholder="Type a Message" fdprocessedid="0p3op" />
                                                </div>
                                                <ul className="list-unstyledn mb-0 d-flex align-items-center">
                                                    <li>
                                                        <a className="text-dark px-2 fs-7 bg-hover-primary nav-icon-hover position-relative z-index-5"
                                                           href="javascript:void(0)"><i
                                                            className="ti ti-photo-plus"></i></a>
                                                    </li>
                                                    <li>
                                                        <a className="text-dark px-2 fs-7 bg-hover-primary nav-icon-hover position-relative z-index-5"
                                                           href="javascript:void(0)"><i className="ti ti-paperclip"></i></a>
                                                    </li>
                                                    <li>
                                                        <a className="text-dark px-2 fs-7 bg-hover-primary nav-icon-hover position-relative z-index-5"
                                                           href="javascript:void(0)"><i
                                                            className="ti ti-microphone"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>}
                                    </div>
                                    {!props.hideDetails && customers.length > 0 && <div className="app-chat-offcanvas border-start">
                                        <div className="custom-app-scroll mh-n100 simplebar-scrollable-y"
                                             data-simplebar="init">
                                            <div className="simplebar-wrapper" style={{margin: "0px"}}>
                                                <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer"></div>
                                                </div>
                                                <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                                        <div className="simplebar-content-wrapper" tabIndex="0"
                                                             role="region" aria-label="scrollable content"
                                                             style={{height: "auto", overflow: "hidden scroll"}}>
                                                            <div className="simplebar-content" style={{padding: "0px"}}>
                                                                <div className="offcanvas-body p-9">
                                                                    <div className="files-chat">
                                                                        <button className="btn btn-primary w-100" style={{marginTop: "15px"}} onClick={() => {socket.emit(`recentChats:close`, {id: activeUser.id, user_id: AppContext.user.id, status: 1});}}>
                                                                            Close Chat
                                                                        </button>

                                                                        <button className="btn btn-outline-primary w-100" style={{marginTop: "15px", marginBottom: "15px", }} onClick={()=>setShowSwitchAgentDld(true)}>
                                                                            Switch Agent
                                                                        </button>

                                                                        <h5 className="card-title fw-semibold">Contact Details:</h5>

                                                                        {clientDetails.map(item => {
                                                                            return (
                                                                                <a href="javascript:void(0)" className="hstack gap-3 file-chat-hover justify-content-between text-nowrap mb-9">
                                                                                    <div className="d-flex align-items-center gap-3">
                                                                                        <div className="rounded-1 text-bg-light p-6">
                                                                                            <img src={`${PUBLIC_URL}/assets/images/chat/icon-figma.svg`} alt="" width="24" height="24"/>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h6 className="fw-semibold">
                                                                                                {item.name}:
                                                                                            </h6>
                                                                                            <div className="d-flex align-items-center gap-3 fs-2 text-muted">
                                                                                                <span>{item.val}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            )
                                                                        })}

                                                                        <button className="btn btn-outline-primary w-100" onClick={()=>setShowRecentChatsDld(true)}>
                                                                            View all Requests
                                                                        </button>

                                                                        <button className="btn btn-outline-primary w-100" style={{marginTop: "15px"}} onClick={()=>setShowCustomerMessagesDld(true)}>
                                                                            View all messages
                                                                        </button>

                                                                        <button className="btn btn-primary w-100" style={{marginTop: "15px"}} onClick={()=>{socket.emit(`pagehistory:list`, {customer_token: clientToken, id: onlineID});setShowPageViewDld(true)}}>
                                                                            Page View History
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="simplebar-placeholder"
                                                     style={{width: "299px", height: "619px"}}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-horizontal"
                                                 style={{visibility: "hidden"}}>
                                                <div className="simplebar-scrollbar"
                                                     style={{width: "0px", display: "none"}}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-vertical"
                                                 style={{visibility: "visible"}}>
                                                <div className="simplebar-scrollbar"
                                                     style={{height: "210px", display: "block", transform: "translate3d(0px, 0px, 0px)"}}></div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
export  default  ChatRequests;
