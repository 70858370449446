import React, { useState, useEffect } from 'react';

import BasicCRUD from './../core/basic-crud'
import {PUBLIC_URL} from './../../const'

import { socket, requestData, listener } from './../../socket';

const Agents = (props) => {
    const object = "agents"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})
    const [departments, setDepartmentsData] = useState([])

    useEffect(() => {
        socket.on(`departments:list-data`, (data) => {
            setDepartmentsData(data.data)
        });

        requestData("departments", {}, "list");

        return () => {
            socket.off(`departments:list-data`);
        };
    }, []);

    const DrawAgent = (data) => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <span className="position-relative">
                        <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`} alt="user1" width="48" height="48" className="rounded-circle" />
                        <span className="position-absolute bottom-0 end-0 p-1 badge rounded-pill bg-success">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </span>
                    <div className="ms-3 d-inline-block w-75">
                        <h6 className="mb-1 fw-semibold chat-title">{data['name']}</h6>
                        <span className="fs-3 text-truncate text-body-color d-block">{data['email']}</span>
                    </div>
                </div>

            </>
        )
    }

    const  columns = [
        {label:"Agent", key: "name", format: DrawAgent,},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const fields = [
        {
            type: "wizard",//tab
            name: "User Details",
            image: "ti ti-home-2 fs-4",
            view: "cycle",
            fields: [
                [
                    {label: "First Name", key: "first_name"},
                    {label: "Last Name", key: "last_name"},
                ],
                [
                    {label: "Location", key: "location"},
                ],
            ]
        },
        {
            type: "tab",
            name: "Account",
            image: "ti ti-user fs-4",
            fields: [
                [
                    {label: "Email", key: "email"},
                ],
                [
                    {label: "Password", key: "pass", type: "password"},
                    {label: "Confirm Password", key: "confirm_pass", type: "password"},
                ],
            ]
        },
        {
            type: "tab",
            name: "Departments",
            image: "ti ti-user fs-4",
            fields: [
                [
                    {label: "Choose Departments", data: departments, key: "departments", type: "checkbox"},
                ],
            ]
        }
    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    return (
        <>
            <BasicCRUD
                title={"Agents"}
                homeURL={"/dashboard"}
                addTitle="Add Agent" editTitle={"Edit Agent"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                multiActions={multiActions} >

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Agent</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Agent</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Agent</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  Agents;
