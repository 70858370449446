import React, { useState, useEffect } from 'react';
import PageTitle from "./../core/page-title"
import RenderFormFields from './../core/forms'
import {useNavigate} from 'react-router'

import { socket, requestData, socketUseEffect } from './../../socket';

const Profile = (props) => {
    const object = "agents"

    const [data, setData] = useState([])

    const listeners = [
        {name: "users", fn: "list", args: {}, saveDataFN: setData},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const [fieldsData, setFieldsData] = useState(data.data || {})
    const [response, setResponse] = useState({});

    const navigate = useNavigate();

    const Devices = (props) => {
        return (
            <>
                <div className="card">
                    <div className="card-body p-4">
                        <div
                            className="text-bg-light rounded-1 p-6 d-inline-flex align-items-center justify-content-center mb-3">
                            <i className="ti ti-device-laptop text-primary d-block fs-7" width="22" height="22"></i>
                        </div>
                        <h5 className="fs-5 fw-semibold mb-0">Devices</h5>
                        <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit Rem.</p>
                        <button className="btn btn-primary mb-4">Sign out from all devices</button>
                        <div className="d-flex align-items-center justify-content-between py-3 border-bottom">
                            <div className="d-flex align-items-center gap-3">
                                <i className="ti ti-device-mobile text-dark d-block fs-7" width="26" height="26"></i>
                                <div>
                                    <h5 className="fs-4 fw-semibold mb-0">iPhone 14</h5>
                                    <p className="mb-0">London UK, Oct 23 at 1:15 AM</p>
                                </div>
                            </div>
                            <a className="text-dark fs-6 d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                               href="javascript:void(0)">
                                <i className="ti ti-dots-vertical"></i>
                            </a>
                        </div>
                        <div className="d-flex align-items-center justify-content-between py-3">
                            <div className="d-flex align-items-center gap-3">
                                <i className="ti ti-device-laptop text-dark d-block fs-7" width="26" height="26"></i>
                                <div>
                                    <h5 className="fs-4 fw-semibold mb-0">Macbook Air</h5>
                                    <p className="mb-0">Gujarat India, Oct 24 at 3:15 AM</p>
                                </div>
                            </div>
                            <a className="text-dark fs-6 d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                               href="javascript:void(0)">
                                <i className="ti ti-dots-vertical"></i>
                            </a>
                        </div>
                        <button className="btn bg-primary-subtle text-primary w-100 py-1">Need Help ?</button>
                    </div>
                </div>
            </>
        )
    }
    const twoFAComponent = (props) => {
        return (
            <div className="card">
                <div className="card-body p-4">
                    <h4 className="fw-semibold mb-3">Two-factor Authentication</h4>
                    <div className="d-flex align-items-center justify-content-between pb-7">
                        <p className="mb-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis sapiente
                            sunt earum officiis laboriosam ut.</p>
                        <button className="btn btn-primary">Enable</button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 border-top">
                        <div>
                            <h5 className="fs-4 fw-semibold mb-0">Authentication App</h5>
                            <p className="mb-0">Google auth app</p>
                        </div>
                        <button className="btn bg-primary-subtle text-primary">Setup</button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 border-top">
                        <div>
                            <h5 className="fs-4 fw-semibold mb-0">Another e-mail</h5>
                            <p className="mb-0">E-mail to send verification link</p>
                        </div>
                        <button className="btn bg-primary-subtle text-primary">Setup</button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 border-top">
                        <div>
                            <h5 className="fs-4 fw-semibold mb-0">SMS Recovery</h5>
                            <p className="mb-0">Your phone number or something</p>
                        </div>
                        <button className="btn bg-primary-subtle text-primary">Setup</button>
                    </div>
                </div>
            </div>
        )
    }

    const fields = [
        {
            type: "tab",
            name: "Account",
            image: "ti ti-home-2 fs-4",
            view: "cycle",
            fields: [
                [
                    {label: "Change Profile", subLabel: "Change your profile picture from here", type: "group",
                        fields:[
                            [
                                {label: "", key: "avater", type: "avatar"},
                            ]

                        ]
                    },
                    {label: "Change Password", subLabel: "To change your password please confirm here", type: "group",
                        fields:[
                            [
                                {label: "Current Password", key: "cur_pass", type: "password"},
                            ],
                            [
                                {label: "New Password", key: "new_pass", type: "password"},
                            ],
                            [
                                {label: "Confirm Password", key: "conf_pass", type: "password"},
                            ]
                        ]
                    },
                ],
                [
                    {label: "Personal Details", subLabel: "To change your personal detail , edit and save from here", type: "group",
                        fields:[
                            [
                                {label: "First Name", key: "first_name", showLabel: "top"},
                                {label: "Last Name", key: "last_name", showLabel: "top"},
                            ],
                            [
                                {label: "Email", key: "email", showLabel: "top"},
                                {label: "Phone", key: "phone", showLabel: "top"},
                            ],
                            [
                                {label: "Location", key: "location", showLabel: "top"},
                                {label: "Address", key: "address", showLabel: "top"},
                            ],
                        ]
                    },
                ],
            ]
        },
        {
            type: "tab",
            name: "Notifications",
            image: "ti ti-user fs-4",
            align: "left",
            fields: [
                [
                    {label: "Notification Preferences", subLabel: "Select the notificaitons ou would like to receive via email. Please note that you cannot opt out of receving service messages, such as payment, security or legal notifications.", type: "group",
                        fields:[
                            [
                                {label: "Email Address", subLabel: "Required for notificaitons.", key: "notifications_email", showLabel: "top"},
                            ],
                            [
                                {icon: "ti-article", label: "Our newsletter", subLabel: "We'll always let you know about important changes", key: "notifications_newslater", type: "switch"},
                            ],
                            [
                                {icon: "ti-mail", label: "New Messages", subLabel: "We'll always let you know about new messages", key: "notifications_messages", type: "switch"},
                            ],
                            [
                                {icon: "ti-checkbox", label: "New Chat Requests", subLabel: "We'll always let you know about new chat requests", key: "notifications_chatrequests", type: "switch"},
                            ],
                        ],
                        columns: 8,
                    },
                ],
                [
                    {label: "Date & Time", subLabel: "Time zones and calendar display settings.", type: "group",
                        fields:[
                            [
                                {label: "Time zone", key: "timw_zone", type: "selectbox"},
                            ],
                        ],
                        columns: 8,
                    },
                ],
            ]
        },
        {
            type: "tab",
            name: "Security",
            image: "ti ti-user fs-4",
            fields: [
                [
                    {label: "Two-factor Authentication", type: "custom", fn: twoFAComponent},
                    {label: "Devices", type: "custom", fn: Devices},
                ],
            ]
        }
    ]

    return (
        <>
            <PageTitle title="Account Setting" homeUrl={"/dashboard"} />

            <RenderFormFields fields={fields} fieldsData={fieldsData} fieldsChange={setFieldsData} response={response} isForm={true} cancelAction={() => {navigate(`/dashboard`, { replace: true });}} saveAction={() => {navigate(`/dashboard`, { replace: true });}} />
        </>
    )
}

export  default  Profile;
