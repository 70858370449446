import React, { useState } from 'react';
import {Link} from "react-router-dom";

import BasicCRUD from './../core/basic-crud'


const Abbreviations = (props) => {
    const object = "abbreviations"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const  columns = [
        {label:"Key", key: "name"},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const fields = [
        {
            type: "tab",
            name: "",
            image: "",
            fields: [
                [{label: "Name", key: "name"}],
                [{label: "Data", key: "data", type: "textarea", rows: 10}]
            ]
        },

    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [
        {name: "multiDelete", label: "Delete All", controller: "crud"}
    ]

    return (
        <>
            <BasicCRUD
                title={"Settings (`Abbreviations`)"}
                subTitle={"Abbreviations"}
                homeURL={"/dashboard"}
                addTitle="Add Abbreviation" editTitle={"Edit Abbreviation"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                multiActions={multiActions} >

                    {response.status && response.status === 1 ? (
                        <>
                            <div className="alert alert-success" role="alert">
                                {response.created && response.created === 1 ? (
                                    <>
                                        <strong>Abbreviation</strong> created successfully
                                    </>
                                ): null}
                                {response.updated && response.updated === 1 ? (
                                    <>
                                        <strong>Abbreviation</strong> updated successfully
                                    </>
                                ): null}
                                {response.delete && response.delete === 1 ? (
                                    <>
                                        <strong>Abbreviation</strong> deleted successfully
                                    </>
                                ): null}
                            </div>

                        </>

                    ) : null}

                <ul className="nav nav-pills user-profile-tab" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to="/sites" className="sidebar-link" data-text="Sites" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Sites</span>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to="/departments" className="sidebar-link" data-text="Dashboard" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Departments</span>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to="/abbreviations" className="sidebar-link" data-text="Dashboard" >
                            <button className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4 active`}
                                    id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button"
                                    role="tab" aria-controls="pills-account" aria-selected="true">
                                <i className="ti ti-user-circle me-2 fs-6"></i>
                                <span className="d-none d-md-block">Abbreviations</span>
                            </button>
                        </Link>
                    </li>
                </ul>

            </BasicCRUD>
        </>
    )
}
export  default  Abbreviations;
