import React, { useState } from 'react';
import {Link} from "react-router-dom";

import BasicCRUD from './../core/basic-crud'
import {DrawTime} from './sessions/views-templates'


const PageViews = (props) => {
    const object = "pagehistory"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const  columns = [
        {label:"Page", key: "url"},
        {label:"View Duration", key: "duration", format: DrawTime},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    return (
        <>
            <BasicCRUD
                hideTitle={true}
                hideGroupActions={true}
                hideActions={true}
                disableSaveFilters={true}
                args={props.filters || {}}
                object={object} columns={columns}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                filters={props.filters || {}}
                ownHandler={true}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Abbreviation</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Abbreviation</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Abbreviation</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  PageViews;
